import React from 'react'
import styled from 'styled-components'
import { createSlide, fetchAllFiles, requestSlides } from '../../utils/api'
import { Title } from '../../components/Typography'
import * as Yup from 'yup'
import Input from '../../components/Input'
import { Field, Formik } from 'formik'
import { connect } from 'react-redux'
import { Button } from '../../components/Buttons'
import Pagination from '../../components/Pagination'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
`

const ViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.space[1]}px 0;
  padding: ${({ theme }) => theme.space[1]}px 0;
  min-height: ${({ theme }) => theme.space[5]}px;
`

const PreviewContainer = styled.div`
  display: flex;
  min-height: ${({ theme }) => theme.space[5]}px;
`

const Preview = styled.div`
  background: ${({ url }) => `url(${encodeURI(url)})`};
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${({ theme }) => theme.space[1]}px;
  border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  
  &:hover {
    border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.primary};
  }
`

class SlideForm extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            filePagination: {
                page: 0,
                size: 10
            },
            files: []
        }
    }

    componentDidMount () {
        this.fetchFiles()
    }

    async fetchFiles () {
        try {
            const { filePagination: { page, size }} = this.state
            const response = await fetchAllFiles(page, size)
            const newState = {...this.state}
            newState.files = response.data.content
            this.setState(newState)
        } catch (error) {
            console.error('Error fetching files', error)
        }
    }

    async onSubmit (slide) {
        try {
            await createSlide(slide)
        } catch (error) {
            console.error('Error creating new slide', error)
        }
    }

    nextPage () {
        const { filePagination: { page }} = this.state
        const newState = { ...this.state }
        newState.filePagination.page = page + 1
        this.setState(newState, this.fetchFiles)
    }

    prevPage () {
        const { filePagination: { page }} = this.state
        if (page > 0) {
            const newState = { ...this.state }
            newState.filePagination.page = page - 1
            this.setState(newState, this.fetchFiles)
        }
    }

    render () {
        const { files, filePagination: { page }} = this.state

        return (
            <Container>
                <ViewerContainer>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ location: '', link: '' }}
                        validationSchema={Yup.object().shape({
                            location: Yup.string().required(),
                            link: Yup.string().url('Value needs to be a valid URL'),
                        })}
                        onSubmit={(values) => {
                            this.onSubmit(values)
                        }}
                        validateOnBlur={true}
                    >{formikProps => (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Header>
                                    <Title>
                                        Add New Slide
                                    </Title>
                                </Header>
                                <Field
                                    component={Input}
                                    name="location"
                                />
                                <Field
                                    component={Input}
                                    name="link"
                                />
                                <Button type="submit" disabled={!formikProps.isValid}>
                                    Save
                                </Button>
                                <Header>
                                    <Title>
                                        Files
                                    </Title>
                                </Header>
                                <Pagination refresh={() => this.fetchFiles()} page={page} prevPage={() => this.prevPage()} nextPage={() => this.nextPage()} />
                                <PreviewContainer>
                                    {files.map(file => (<Preview key={`file-${file.id}`} url={file.path} onClick={() => formikProps.setFieldValue('location', file.path)} />))}
                                </PreviewContainer>
                            </form>
                        )}
                    </Formik>
                </ViewerContainer>
            </Container>
        )
    }
}

const mapDispatchToProps = { createSlide }

export default connect(null, mapDispatchToProps)(
    SlideForm
)
