import * as types from '../LiveForm/constants'
import {fetchLiveById, getLive, liveLogin} from '../../utils/api'

const setLiveToken = (token) => {
    console.log('Live token saved')
    if (token) {
        localStorage.setItem('liveToken', token)
    }
}

const getLiveToken = () => {
    return localStorage.getItem('liveToken')
}

export function liveLoginSuccess (uuid) {
    setLiveToken(uuid)
    return {
        type: types.LIVE_LOGIN_SUCCESS
    }
}

export function liveLoginFailure () {
    return {
        type: types.LIVE_LOGIN_FAILURE,
        payload: null
    }
}

export function fetchLiveRequest () {
    return {
        type: types.GET_LIVE_EVENT,
        payload: null
    }
}

export function fetchLiveSuccess (liveEvent) {
    return {
        type: types.GET_LIVE_EVENT_SUCCESS,
        payload: liveEvent
    }
}

export function fetchLiveFailure () {
    return {
        type: types.GET_LIVE_EVENT_FAILURE,
        payload: null
    }
}

export function fetchLiveAction (code) {
    return async function (dispatch) {
        try {
            dispatch(fetchLiveRequest())
            const token = getLiveToken();
            const response = await getLive(code, token);
            dispatch(fetchLiveSuccess(response.data))
            return response.data
        } catch (error) {
            console.log('error')
            dispatch(fetchLiveFailure())
            throw error
        }
    }
}

export function selectLiveMenuAction (payload = types.LIVE_MENU_SELECTION_PAGE) {
    return {
        type: types.SELECT_LIVE_MENU,
        payload
    }
}
