import * as types from './constants'
import { showAlert } from '../../components/Alert/actions'
import { getDefaultPrimary } from '../../styles'

export function getThemeMode () {
    return localStorage.getItem('theme')
}

export function checkTheme () {
    return function (dispatch) {
        const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        const theme = getThemeMode()
        if (!theme) {
            if (userPrefersDark) {
                dispatch(setDarkMode())
            } else {
                dispatch(setLightMode())
            }
        } else {
            if (theme === 'light') {
                dispatch(setLightMode())
            } else {
                dispatch(setDarkMode())
            }
        }
        return {
            type: types.CHECK_THEME,
            payload: null
        }
    }
}

export function setDarkMode () {
    localStorage.setItem('theme', 'dark')
    return {
        type: types.TOGGLE_THEME,
        payload: 'dark'
    }
}

export function setLightMode () {
    localStorage.setItem('theme', 'light')
    return {
        type: types.TOGGLE_THEME,
        payload: 'light'
    }
}

export function checkSubscription () {
    return function (dispatch) {
        const subscribed = localStorage.getItem('subscribed')
        if (subscribed === 'true') {
            dispatch(subscribeEmail())
        }
        return {
            type: types.CHECK_SUBSCRIPTION,
            payload: null
        }
    }
}

export function unsubscribeEmail () {
    localStorage.removeItem('subscribed')
    return {
        type: types.UNSUBSCRIBE,
        payload: 'false'
    }
}

export function subscribeEmail () {
    localStorage.setItem('subscribed', 'true')
    return {
        type: types.SUBSCRIBE,
        payload: 'true'
    }
}

export function setPrimaryColor (color, save, notify = true) {
    return function (dispatch) {
        dispatch(_setPrimaryColor(color, save))

        if (notify) {
            dispatch(showAlert('Changing primary color', 7000))
        }
    }
}

function _setPrimaryColor (color, save = true) {
    if (save) {
        localStorage.setItem('primary', color)
    }
    return {
        type: types.CHANGE_COLOR,
        payload: color
    }
}

export function checkPrimaryColor () {
    return function (dispatch) {
        const color = localStorage.getItem('primary')

        if (color) {
            dispatch(setPrimaryColor(color, false, false))
            dispatch(_checkPrimaryColor())
        }
    }
}

export function _checkPrimaryColor () {
    return {
        type: types.CHECK_COLOR
    }
}

export function resetPrimaryColor () {
    localStorage.removeItem('primary')
    return {
        type: types.RESET_COLOR,
        payload: getDefaultPrimary
    }
}
