import * as types from "./constants";
import {deleteFileById, fetchFileById} from "../../utils/api";

export function getFileRequest() {
    return {
        type: types.GET_FILE,
        payload: null
    }
}

export function getFileSuccess(file) {
    return {
        type: types.GET_FILE_SUCCESS,
        payload: file
    }
}

export function getFileFailure() {
    return {
        type: types.GET_FILE_FAILURE,
        payload: null
    }
}

export function fetchFileAction(fileId) {
    return async function (dispatch) {
        try {
            dispatch(getFileRequest());
            const response = await fetchFileById(fileId);
            const file = response.data;
            dispatch(getFileSuccess(file));
            return file;
        } catch (error) {
            dispatch(getFileFailure());
            throw error;
        }
    };
}

export function deleteFileRequest() {
    return {
        type: types.DELETE_FILE,
        payload: null
    }
}

export function deleteFileSuccess(file) {
    return {
        type: types.DELETE_FILE_SUCCESS,
        payload: file
    }
}

export function deleteFileFailure() {
    return {
        type: types.DELETE_FILE_FAILURE,
        payload: null
    }
}

export function deleteFileAction(fileId) {
    return async function (dispatch) {
        try {
            dispatch(deleteFileRequest());
            const response = await deleteFileById(fileId);
            dispatch(deleteFileSuccess());
            return response;
        } catch (error) {
            dispatch(deleteFileFailure());
            throw error;
        }
    };
}