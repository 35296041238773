import React, { useState } from 'react'
import styled from 'styled-components'
import Slideshow from '../../containers/Slideshow'
import { ReactComponent as DownArrow } from '../../images/down-arrow.svg'
import Jump from 'react-reveal/Jump'

const HeroContainer = styled.div`
    background-position: center;
    background-size: cover;
    height: 90vh;
    font-family: "Quicksand-Bold,serif";
    box-shadow: 0px 0px 5px 2px black;
    z-index: 1;
    //position: relative;
`

const ScrollDownIcon = styled.div`
    height: 100px;
    bottom: ${({ theme }) => theme.space[2]}px;
    position: absolute;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -65%);
`

const HOME_SLIDESHOW = 'HOME_SLIDESHOW'

const Hero = () => {
    const [hover, setHoverCount] = useState(0)

    const onHover = () => {
        setHoverCount(hover + 1)
    }

    return (
        <HeroContainer onMouseEnter={onHover}>
            <Slideshow slideshowName={HOME_SLIDESHOW} slideHeight="65vh" />
            <Jump spy={hover}>
                <ScrollDownIcon>
                    <DownArrow fill="white" />
                </ScrollDownIcon>
            </Jump>
        </HeroContainer>
    )
}

export default Hero
