import styled, {css} from "styled-components";
import {position, space, typography} from "styled-system";
import {NavLink} from "react-router-dom";

export const Item = css`
    display: flex;
    ${space};
    ${typography};
    cursor: pointer;
    border-radius: 2px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0 !important;
    }
`

export const NavItem = styled(NavLink)`
    ${Item}
    &:hover {
      background-color: ${({ theme, inverted }) => inverted ? theme.colors.banner : theme.colors.primary};
    }
`

export const NavDropdown = styled.div`
  display: flex;
  position: relative;
`
export const NavDropdownOptions = styled.ol`
    ${position};
    position: absolute;
    margin: 0;
    background-color: ${({ theme, inverted }) => inverted ? theme.colors.primary : theme.colors.banner};
    padding: 10px 0;
    right: 0;
    z-index: 15;
    border: 1px solid ${({ theme, inverted }) => theme.colors.border};
`
export const NavDropdownOption = styled.li`
  ${Item}
  ${typography};
  padding: 10px;
  &:hover {
    background-color: ${({ theme, inverted }) => inverted ? theme.colors.banner : theme.colors.primary};
  }
`

export const NavButton = styled.a`
  ${Item}
  &:hover {
    background-color: ${({ theme, inverted }) => inverted ? theme.colors.banner : theme.colors.primary};
  }
`

export const NavPlaceholder = Item