import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Login from '../Login'
import Portfolio from '../Portfolio'
import Home from '../Home'
import Feed from '../Feed'
import PostPage from '../PostPage'
import PostForm from '../PostForm'
import PageNotFound from '../PageNotFound'
import Files from '../Files'
import FilePage from '../FilePage'
import FileForm from '../FileForm'
import Slideshows from '../Slideshows'
import SlideshowPage from '../SlideshowPage'
import LitePortfolio from '../LitePortfolio'
import SlideshowForm from '../SlideshowForm'
import SendEmailForm from '../SendEmailForm'
import Live from '../LivePage'
import EventForm from '../EventForm'
import EventList from '../EventList'
import EventPage from '../EventPage'
import LiveForm from '../LiveForm'

const Routes = (props) => {
    const { authentication, lite = false } = props
    return lite ? (
        <LitePortfolio />
    ) : (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>

            <Route exact path="/login">
                <Login />
            </Route>

            <Route exact path="/portfolio">
                <Portfolio />
            </Route>

            <Route exact={true} path="/posts">
                <Feed />
            </Route>

            <Route exact={true} path="/post/:postId/view">
                <PostPage />
            </Route>

            <Route exact={true} path="/live">
                <LiveForm />
            </Route>

            <Route exact={true} path="/live/:code">
                <Live />
            </Route>

            {
                authentication.authenticated ? (
                    <>
                        <Route exact={true} path="/post/new">
                            <PostForm />
                        </Route>
                        <Route exact path="/post/:postId/edit">
                            <PostForm edit={true} />
                        </Route>

                        <Route exact path="/emails">
                            <SendEmailForm />
                        </Route>

                        <Route exact path="/files">
                            <Files />
                        </Route>
                        <Route exact path="/files/new">
                            <FileForm />
                        </Route>
                        <Route exact path="/files/:fileId/view">
                            <FilePage />
                        </Route>
                        <Route exact path="/files/:fileId/edit">
                            <FileForm edit />
                        </Route>

                        <Route exact path="/slideshows">
                            <Slideshows />
                        </Route>
                        <Route exact path="/slideshows/new">
                            <SlideshowForm />
                        </Route>
                        <Route exact path="/slideshows/:slideshowId/view">
                            <SlideshowPage />
                        </Route>
                        <Route exact path="/slideshows/:slideshowId/edit">
                            <SlideshowForm edit={true} />
                        </Route>

                        <Route exact={true} path="/events">
                            <EventList />
                        </Route>

                        <Route exact={true} path="/events/new">
                            <EventForm />
                        </Route>

                        <Route exact={true} path="/events/:eventId/edit">
                            <EventForm edit={true} />
                        </Route>

                        <Route exact={true} path="/events/:eventId/view">
                            <EventPage />
                        </Route>
                    </>
                ) : null
            }

            <Route>
                <PageNotFound />
            </Route>

        </Switch>
    )
}

Routes.propTypes = {
    lite: PropTypes.bool.isRequired
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication
    }
}

export default connect(
    mapStateToProps
)(Routes)
