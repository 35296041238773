import * as types from "./constants";
import {addNewPost, editPostById} from "../../utils/api";
import {getPostRequest} from "../PostPage/actions";

export function editPostRequest(post) {
    return {
        type: types.EDIT_POST,
        payload: null
    }
}


export function editPostSuccess(post) {
    return {
        type: types.EDIT_POST_SUCCESS,
        payload: post
    }
}

export function editPostFailure() {
    return {
        type: types.EDIT_POST_FAILURE,
        payload: null
    }
}

export function editPost(postId, data) {
    return async function (dispatch) {
        try {
            dispatch(editPostRequest());
            const response = await editPostById(postId, data);
            const post = response.data;
            dispatch(editPostSuccess(post));
            return post;
        } catch (error) {
            console.error(error);
            dispatch(editPostFailure());
            throw error;
        }
    };
}

export function addPostRequest(post) {
    return {
        type: types.ADD_POST,
        payload: null
    }
}

export function addPostFailure() {
    return {
        type: types.ADD_POST_FAILURE,
        payload: null
    }
}

export function addPost(data) {
    return async function (dispatch) {
        try {
            dispatch(addPostRequest());
            const response = await addNewPost(data);
            const post = response.data;
            dispatch(getPostRequest(post));
            return post;
        } catch (error) {
            console.error(error);
            dispatch(addPostFailure());
            throw error;
        }
    };
}