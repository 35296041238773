import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './rootReducer'

import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { IS_DEV } from '../constants/env'

let store

// if (IS_DEV === 'true') {
//     store = createStore(rootReducer, {}, compose(applyMiddleware(logger, thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))
// } else {
    store = createStore(rootReducer, applyMiddleware(thunk))
// }

export default store
