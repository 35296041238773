import React from 'react'
import styled, {css, withTheme} from 'styled-components'

import { ReactComponent as InstagramIcon } from '../../images/instagram.svg'
import { ReactComponent as ResumeIcon } from '../../images/resume.svg'
import { ReactComponent as LinkedInIcon } from '../../images/linkedin.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube.svg'
import { ReactComponent as GithubIcon } from '../../images/github.svg'
import { ReactComponent as SoundcloudIcon } from '../../images/soundcloud.svg'
import {userEvent} from "../../utils/analytics";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  padding: ${({ theme }) => theme.space[3]}px;
`

const Icon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 ${({ theme }) => theme.space[2]}px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  fill: ${({ theme }) => theme.colors.text};
  
  &:hover {
    transform: scale(1.5);
    ${({ theme, primary }) => {
        if (primary) {
            return css`
              fill: ${theme.colors.primary};
            `
        }
    }};
  }
`

const SocialLinks = ({ theme, primary }) => {
    const openLink = (url) => {
        window.open(url, '_blank')
    }

    const goToYoutube = () => {
        userEvent('Click', 'Visited Youtube page')
        openLink('https://www.youtube.com/channel/UCygJ5fzETCM4hdOuAX9ogAg')
    }

    const goToInstagram = () => {
        userEvent('Click', 'Visited Instagram')
        openLink('https://www.instagram.com/micerbeats')
    }

    const goToSoundCloud = () => {
        userEvent('Click', 'Visited Soundcloud')
        openLink('https://soundcloud.com/user-834881021')
    }

    const goToGithub = () => {
        userEvent('Click', 'Visited Github')
        openLink('https://github.com/itsMIcah')
    }

    const goToLinkedIn = () => {
        userEvent('Click', 'Visited LinkedIn')
        openLink('https://www.linkedin.com/in/micah-prescott-23388311b/')
    }

    return (
        <Container>
            <Icon title="Youtube" primary={primary} onClick={goToYoutube}>
                <YoutubeIcon width="35px" height="35px" />
            </Icon>
            <Icon title="Instagram" primary={primary} onClick={goToInstagram}>
                <InstagramIcon width="35px" height="35px" />
            </Icon>
             {/*<Icon title="Soundcloud" primary={primary} onClick={goToSoundCloud}>*/}
             {/*   <SoundcloudIcon width="40px" height="40px" />*/}
             {/*</Icon>*/}
            <Icon title="LinkedIn" primary={primary} onClick={goToLinkedIn}>
                <LinkedInIcon width="35px" height="35px" />
            </Icon>
            <Icon title="Github" primary={primary} onClick={goToGithub}>
                <GithubIcon width="35px" height="35px" />
            </Icon>
        </Container>
    )
}

export default withTheme(SocialLinks)
