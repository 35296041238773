import React from 'react'
import { Button } from '../Buttons'
import { ModalButtons, ModalContainer, ModalSubtitle, ModalTitle } from '../../styles/Modal'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'
import { resetPrimaryColor, setPrimaryColor } from '../../containers/App/actions'
import styled, { withTheme } from 'styled-components'
import * as Yup from 'yup'
import ColorPicker from '../ColorPicker'
import { userEvent } from '../../utils/analytics'

const ColorPickerContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ColorPickerModal = ({ closeModal, app, setPrimaryColor, resetPrimaryColor, theme }) => {
    const onSave = (values) => {
        if (values) {
            userEvent('Click', `Set a new primary color: ${values.color}`)
            setPrimaryColor(values.color)
            closeModal()
        }
    }

    const onReset = () => {
        userEvent('Click', 'Reset Primary Color Selection')
        resetPrimaryColor()
        closeModal()
    }

    const initialValues = {
        color: ''
    }

    const schema = Yup.object().shape({
        color: Yup.string().required()
    })

    return (
        <ModalContainer
            p={[2, 2, 3, 4]}
            width={[0.9, 0.8, 0.7, 1 / 2]}
        >
            <ModalTitle m={[2,3,4]}>
                Select a New Primary Color
            </ModalTitle>
            <ModalSubtitle
                p={[0, 2, 3]}
                mt={[3, 3, 3, 3, 4]}
                mb={[3, 3, 3, 3, 4]}
            >
                Click the color bar below to select a new primary color
            </ModalSubtitle>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => {
                    onSave(values)
                }}
                validateOnBlur={true}
            >{formikProps => (
                    <ColorPickerContainer onSubmit={formikProps.handleSubmit}>
                        <Field
                            render={ props => (<ColorPicker {...formikProps} {...props} />) }
                            name="color"
                        />
                        <ModalButtons
                            p={[1, 1, 2, 3, 4]}
                            m={[1, 1, 2]}
                        >
                            <Button
                                ml={[2]}
                                mr={[2]}
                                type="button" onClick={closeModal}>
                            Close
                            </Button>
                            <Button
                                type="button"
                                onClick={onReset}
                                ml={[2]}
                                mr={[2]}
                            >
                                Reset
                            </Button>
                            <Button
                                primary
                                type="submit"
                                button
                                m={[2]}
                                mr={[2]}
                                disabled={!formikProps.isValid}
                                onClick={() => onSave()}>
                            Save
                            </Button>
                        </ModalButtons>
                    </ColorPickerContainer>
                )}
            </Formik>
        </ModalContainer>
    )
}

const mapDispatchToProps = { setPrimaryColor, resetPrimaryColor }

const mapStateToProps = (state /*, ownProps */) => {
    return {
        app: state.app
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTheme(ColorPickerModal)
)
