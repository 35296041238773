import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { fetchSlideshowByName } from './actions'
import Slide from './components/Slide'

const SlideshowContainer = styled.div`
  position: relative;
  height: inherit;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
`

const SlideshowSlider = styled.div`
       -webkit-transform: translateX(-${props => props.transform}px);
      transform: translateX(-${props => props.transform}px);
      
      transition: transform 1s linear;
      height: 100%;
      width: ${props => props.width}px;
      display: flex;
      will-change: transform;
`

const Slideshow = (props) => {
    const { slideshows, slideshowName, fetchSlideshowByName } = props
    const slideshow = slideshows[slideshowName]
    const totalSlides = slideshow ? slideshow.slides.length * 5 : 0

    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const [width, setWidth] = useState(window.innerWidth)

    const loadSlideshow = useCallback(async () => {
        try {
            await fetchSlideshowByName(slideshowName)
        } catch (error) {
            console.error(`Error fetching slideshow by name ${slideshowName}`, error)
        }
    }, [fetchSlideshowByName, slideshowName])

    const changeSlide = () => {
        const newIndex = activeSlideIndex + 1
        setActiveSlideIndex(newIndex)
    }

    useEffect(() => {
        const windowResizeEvent = window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
        return windowResizeEvent
    }, [])

    useEffect(() => {
        loadSlideshow()
    }, [loadSlideshow, slideshowName])

    // useEffect( () => {
    //     let interval = setInterval(function() {
    //         changeSlide()
    //     }.bind(this), 7500)
    //
    //     return () => clearInterval(interval);
    // }, [activeSlideIndex]);

    let slides = []

    if (slideshow) {
        for (let i = 0; i < totalSlides; i++) {
            slides = slides.concat(slideshow.slides)
        }
    }

    return (
        <SlideshowContainer onClick={() => changeSlide()}>
            <SlideshowSlider activeIndex={activeSlideIndex} width={totalSlides * width} transform={activeSlideIndex * width}>
                { slides.map((slide, i) => (<Slide key={i} {...slide} />)) }
            </SlideshowSlider>
        </SlideshowContainer>
    )
}

Slideshow.propTypes = {
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slideshowName: PropTypes.string.isRequired
}

Slideshow.defaultProps = {
    slideHeight: '100%'
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        slideshows: state.slideshows
    }
}

const mapDispatchToProps = { fetchSlideshowByName }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Slideshow)
