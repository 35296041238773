import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import PageContainer from "../PageContainer";
import DataGrid from "../../components/DataGrid";
import { fetchAllEvents } from "../../utils/api";
import EventActionMenu from "../../components/ActionMenu/EventActionMenu";
import {PageTitle} from "../../components/Typography";

class EventList extends React.Component {

    constructor() {
        super();

        this.state = {
            events: []
        }
    }

    componentDidMount() {
        this.fetchEvents();
    }

    appendEvents = (newEvents) => {
        let { events } = this.state;
        this.setState({events: events.concat(newEvents)});
    }

    fetchEvents = async (page = 0, size = 10) => {
        try {
            const response = await fetchAllEvents(page, size);
            this.appendEvents(response.data.content);
            if (! response.data.last) {
                this.fetchEvents(page + 1)
            }
        } catch(error) {
            console.error("Error fetching all events", error);
        }
    }

    render() {
        const { events } = this.state;

        const columns = [
            { label: "Title", field: "title" },
            { label: "Code", field: "code" },
            { label: "Start Date", field: "startDate" },
            { label: "Active", field: "active" }
        ];

        const navigateToEvent = (event) => {
            const { history } = this.props;
            history.push(`/events/${event.id}/view`);
        }

        return (
            <PageContainer direction="column">
                <PageTitle>
                    Events
                </PageTitle>
                <DataGrid onRowClick={navigateToEvent} selectable={true} columns={columns} data={events} />
                <EventActionMenu />
            </PageContainer>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        events: state.events
    }
};

// const mapDispatchToProps = { fetchEventsAction: fetchEventsAction };

export default connect(mapStateToProps)(
    withRouter(EventList)
);
