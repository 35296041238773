import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { space, layout } from 'styled-system'

const Img = styled.img`
    ${layout};  
    ${space};
    border-radius: 3px;
`

const ImageElement = ({ src, ...props }) => {
    return (
        <Img src={src} {...props} />
    )
}

ImageElement.propTypes = {
    src: PropTypes.string.isRequired
}

ImageElement.defaultProps = {
    width: 'auto',
    height: '250px'
}

export default ImageElement
