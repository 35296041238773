export const ADD_SLIDESHOW = 'ADD_SLIDESHOW'
export const ADD_SLIDESHOW_SUCCESS = 'ADD_SLIDESHOW_SUCCESS'
export const ADD_SLIDESHOW_FAILURE = 'ADD_SLIDESHOW_FAILURE'

export const EDIT_SLIDESHOW = 'EDIT_SLIDESHOW'
export const EDIT_SLIDESHOW_SUCCESS = 'EDIT_SLIDESHOW_SUCCESS'
export const EDIT_SLIDESHOW_FAILURE = 'EDIT_SLIDESHOW_FAILURE'

export const GET_SINGLE_SLIDESHOW = 'GET_SINGLE_SLIDESHOW'
export const GET_SINGLE_SLIDESHOW_SUCCESS = 'GET_SINGLE_SLIDESHOW_SUCCESS'
export const GET_SINGLE_SLIDESHOW_FAILURE = 'GET_SINGLE_SLIDESHOW_FAILURE'
