import React from 'react'
import styled from 'styled-components'
import { InputContainer, InputError, InputGroup, InputLabel } from '../Form'

const CheckboxInput = styled.input`
  display: flex;
  width: ${({ theme }) => theme.space[4]}px;
  height: ${({ theme }) => theme.space[4]}px;
  cursor: pointer;
`

const Checkbox = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <InputContainer>
        <InputLabel>
            {field.name}
        </InputLabel>
        <InputGroup>
            <CheckboxInput type="checkbox" {...field} {...props} />
            <InputError>{ touched[field.name] && errors[field.name] ? errors[field.name] : ' ' }</InputError>
        </InputGroup>
    </InputContainer>
)

export default Checkbox
