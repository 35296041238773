import React, { useEffect } from 'react'
import styled from 'styled-components'
import {space, flexbox} from 'styled-system'
import { connect } from 'react-redux'

import { LoadingScreen } from './components/Loading'
import { checkAuthentication } from '../Login/actions'
import Modal from '../../components/Modal'

const PageFrame = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    flex-grow: 1;
    ${space};
    ${flexbox};
`

const PageContainer = (props) => {
    const { loading, children, checkAuthentication, authentication, direction = 'column', styles} = props

    const checkForAuthentication = async () => {
        if (!authentication.authenticated) {
            try {
                await checkAuthentication()
            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        checkForAuthentication()
    }, [])

    return (
        <PageFrame
            direction={direction}
            mt="50px"
            mb="50px"
            ml={[3, 4, 5, 6, 6]}
            mr={[3, 4, 5, 6, 6]}
            {...styles}
        >
            { loading > 0 ? <LoadingScreen /> : (
                <>
                    {children}
                    <Modal />
                </>
            )}
        </PageFrame>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        loading: state.loading,
        authentication: state.authentication
    }
}

const mapDispatchToProps = { checkAuthentication: checkAuthentication }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageContainer)
