import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ReactComponent as UploadIcon } from '../../../images/cloud-upload.svg'
import { layout } from 'styled-system'
import { selectLiveMenuAction } from '../../LiveForm/actions'
import { connect } from 'react-redux'
import { LIVE_MENU_SELECTION_MEDIA } from '../../LiveForm/constants'
import { openModalAction } from '../../../components/Modal/actions'
import { MODAL_TYPE_FILE_VIEWER } from '../../../components/Modal/constants'

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout};
  background: url("${({ url }) => encodeURI(url)}");
  background-position: center;
  background-size: cover;
  transition: ease-in-out .5s;
  background-color: ${({ theme }) => theme.colors.foreground};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.space[2]}px;
  :hover {
    cursor: pointer;
    box-shadow: 0 0 5px 5px ${({ theme }) => theme.colors.darkPrimary};
    transform: scale(1.05);
  }
`

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

const MasonryBlock = ({ path, theme, selectLiveMenuAction, openModalAction, ...props }) => {
    return (
        <Item
            url={path}
            onClick={() => {
                if (!path) {
                    selectLiveMenuAction(LIVE_MENU_SELECTION_MEDIA)
                } else {
                    const caption = props.title || 'Someone shared media'
                    openModalAction(MODAL_TYPE_FILE_VIEWER, path, caption)
                }
            }}
            {...props}
        >
            {
                !path && (
                    <Empty>
                        Click Here to Upload Media
                        <UploadIcon fill={theme.colors.text} height="50px" width="50px" />
                    </Empty>
                )
            }
        </Item>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { selectLiveMenuAction, openModalAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(MasonryBlock))
