import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import PageContainer from "../PageContainer";
import DataGrid from "../../components/DataGrid";
import {fetchAllFiles} from "../../utils/api";
import FileActionMenu from "../../components/ActionMenu/FileActionMenu";
import {PageTitle} from "../../components/Typography";

class Files extends React.Component {

    constructor() {
        super();

        this.state = {
            files: []
        }
    }

    componentDidMount() {
        this.fetchFiles();
    }

    appendFiles = (newFiles) => {
        let { files } = this.state;
        this.setState({files: files.concat(newFiles)});
    }

    fetchFiles = async (page = 0, size = 10) => {
        try {
            const response = await fetchAllFiles(page, size);
            this.appendFiles(response.data.content);
            if (! response.data.last) {
                this.fetchFiles(page + 1)
            }
        } catch(error) {
            console.error("Error fetching all files", error);
        }
    }

    render() {
        const { files } = this.state;

        const columns = [
            { label: "ID", field: "id" },
            { label: "Title", field: "title" },
            { label: "Type", field: "type" },
            { label: "Description", field: "description" }
        ];

        const navigateToFile = (file) => {
            const { history } = this.props;
            history.push(`/files/${file.id}/view`);
        }

        return (
            <PageContainer direction="column">
                <PageTitle>
                    Files
                </PageTitle>
                <DataGrid onRowClick={navigateToFile} selectable={true} columns={columns} data={files} />
                <FileActionMenu />
            </PageContainer>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        files: state.files
    }
};

// const mapDispatchToProps = { fetchFilesAction: fetchFilesAction };

export default connect(mapStateToProps)(
    withRouter(Files)
);
