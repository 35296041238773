import * as types from './constants'
import {addNewEvent, editEventById, fetchEventById} from '../../utils/api'
import {ADD_EVENT_SUCCESS} from "./constants";

export function editEventRequest (event) {
    return {
        type: types.EDIT_EVENT,
        payload: null
    }
}

export function editEventSuccess (event) {
    return {
        type: types.EDIT_EVENT_SUCCESS,
        payload: event
    }
}

export function editEventFailure () {
    return {
        type: types.EDIT_EVENT_FAILURE,
        payload: null
    }
}

export function editEvent (eventId, data) {
    return async function (dispatch) {
        try {
            dispatch(editEventRequest())
            const response = await editEventById(eventId, data)
            const event = response.data
            dispatch(editEventSuccess(event))
            return event
        } catch (error) {
            console.error(error)
            dispatch(editEventFailure())
            throw error
        }
    }
}

export function addEventRequest (event) {
    return {
        type: types.ADD_EVENT,
        payload: null
    }
}

export function addEventSuccess () {
    return {
        type: types.ADD_EVENT_SUCCESS,
        payload: null
    }
}

export function addEventFailure () {
    return {
        type: types.ADD_EVENT_FAILURE,
        payload: null
    }
}

export function addEvent (data) {
    return async function (dispatch) {
        try {
            dispatch(getEventRequest())
            const response = await addNewEvent(data)
            const event = response.data
            dispatch(addEventSuccess(event))
            return event
        } catch (error) {
            console.error(error)
            dispatch(addEventFailure())
            throw error
        }
    }
}

export function deselectEvent() {
    return {
        type: types.DESELECT_EVENT,
        payload: null
    }
}

export function getEventRequest() {
    return {
        type: types.GET_EVENT,
        payload: null
    }
}

export function getEventSuccess(file) {
    return {
        type: types.GET_EVENT_SUCCESS,
        payload: file
    }
}

export function getEventFailure() {
    return {
        type: types.GET_EVENT_FAILURE,
        payload: null
    }
}

export function getEvent(fileId) {
    return async function (dispatch) {
        try {
            dispatch(getEventRequest());
            const response = await fetchEventById(fileId);
            const file = response.data;
            dispatch(getEventSuccess(file));
            return file;
        } catch (error) {
            dispatch(getEventFailure());
            throw error;
        }
    };
}
