import * as types from './constants'

export const fileReducer = (state = {}, { type, payload }) => {
    switch (type) {
    case types.GET_FILE_SUCCESS:
        return payload
    case types.GET_FILE_FAILURE:
        return state
    default:
        return state
    }
}
