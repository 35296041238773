import styled from 'styled-components'
import { layout, space } from 'styled-system'

const ModalBackDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.backdrop};
  z-index: 99;
`

const ModalContainer = styled.div` 
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.foreground};
    justify-content: space-around;
    border-radius: 3px;
    overflow: scroll;
    z-index: 100;
    transform: translateY(-150px);
    ${space};
    ${layout};
}`

const ModalTitle = styled.h2`
  ${space};
  text-align: center;
`

const ModalSubtitle = styled.h4`
  ${space};
  text-align: center;
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${space};
  
  > button {
    font-weight: bold;
    border: 3px solid ${({ theme }) => theme.colors.primary};
  }
`

export {
    ModalBackDrop,
    ModalContainer,
    ModalTitle,
    ModalSubtitle,
    ModalButtons
}
