import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import PageContainer from "../PageContainer";

import EventActionMenu from "../../components/ActionMenu/EventActionMenu";
import {Field, Label, Value} from "../../components/Form";
import {PageTitle} from "../../components/Typography";
import {getEvent} from "../EventForm/actions";

import { ReactComponent as CheckIcon } from '../../images/check.svg'
import { ReactComponent as XIcon } from '../../images/x.svg'
import ImageElement from "../../components/ImageElement";

class EventPage extends React.Component {

    componentDidMount() {
        this.fetchEvent();
    }

    fetchEvent = async () => {
        const { getEvent, match } = this.props;
        const eventId = match.params.eventId;
        try {
            await getEvent(eventId);
        } catch(error) {
            console.error("Error fetching event by id",  error);
        }
    }

    render() {
        const { event = {} } = this.props;
        return (
            <PageContainer direction="column">
                <PageTitle>
                    Event
                </PageTitle>
                <Field>
                    <Label>
                        Title
                    </Label>
                    <Value>
                        {event.title}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Code
                    </Label>
                    <Value>
                        {event.code}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Start Date
                    </Label>
                    <Value>
                        {event.startDate}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Event Banner
                    </Label>
                    <Value>
                        <ImageElement src={event.eventBanner} />
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Event Program
                    </Label>
                    <Value>
                        <ImageElement src={event.eventProgram} />
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Event Survey
                    </Label>
                    <Value>
                        {event.eventSurvey}
                    </Value>
                </Field>


                <Field>
                    <Label>
                        Active
                    </Label>
                    <Value>
                        {event.active ? (
                            <CheckIcon height="50px" fill="green" />
                        ) : (
                            <XIcon height="50px" fill="red" />
                        )}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Raffle Active
                    </Label>
                    <Value>
                        {event.raffle ? (
                            <CheckIcon height="50px" fill="green" />
                        ) : (
                            <XIcon height="50px" fill="red" />
                        )}
                    </Value>
                </Field>
                <EventActionMenu />
            </PageContainer>
        );
    }
}

EventPage.propTypes = {
    eventId: PropTypes.number
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        event: state.event
    }
};

const mapDispatchToProps = { getEvent };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventPage));
