import axiosInstance from './axios'

export const fetchPosts = async (page, size) => {
    return await axiosInstance.request({
        url: '/post',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const ping = async () => {
    return await axiosInstance.request({
        url: '/user/ping',
        method: 'get'
    })
}

export const fetchPostById = async (postId) => {
    return await axiosInstance.request({
        url: `/post/${postId}`,
        method: 'get'
    })
}

export const editPostById = async (postId, post) => {
    return await axiosInstance.request({
        url: `/post/${postId}`,
        method: 'put',
        data: post
    })
}

export const deletePostById = async (postId) => {
    return await axiosInstance.request({
        url: `/post/${postId}`,
        method: 'delete'
    })
}

export const addNewPost = async (post) => {
    return await axiosInstance.request({
        url: '/post',
        method: 'post',
        data: post
    })
}

export const authenticate = async (payload) => {
    return await axiosInstance.request({
        url: '/login',
        method: 'post',
        data: payload
    })
}

export const fetchAllTags = async (page, size) => {
    return await axiosInstance.request({
        url: '/tag',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const fetchAllFiles = async (page, size) => {
    return await axiosInstance.request({
        url: '/file',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const fetchFileById = async (fileId) => {
    return await axiosInstance.request({
        url: `/file/${fileId}`,
        method: 'get'
    })
}

export const editFileById = async (fileId, file) => {
    return await axiosInstance.request({
        url: `/file/${fileId}`,
        method: 'put',
        data: file
    })
}

export const createFile = async (file) => {
    return await axiosInstance.request({
        headers: {
            'content-type': 'multipart/form-data'
        },
        url: '/file',
        method: 'post',
        data: file
    })
}

export const deleteFileById = async (fileId) => {
    return await axiosInstance.request({
        url: `/file/${fileId}`,
        method: 'delete'
    })
}

export const searchPostsByTag = async (tags, page, size) => {
    return await axiosInstance.request({
        url: '/post',
        method: 'post',
        params: {
            page,
            size
        },
        data: tags
    })
}

export const addNewSlideshow = async (slideshow) => {
    return await axiosInstance.request({
        url: '/slideshow',
        method: 'post',
        data: slideshow
    })
}

export const editSlideshowById = async (slideshowId, slideshow) => {
    return await axiosInstance.request({
        url: `/slideshow/${slideshowId}`,
        method: 'put',
        data: slideshow
    })
}

export const fetchSlideshowById = async (slideshowId) => {
    return await axiosInstance.request({
        url: `/slideshow/${slideshowId}`,
        method: 'get'
    })
}

export const deleteSlideshowById = async (slideshowId) => {
    return await axiosInstance.request({
        url: `/slideshow/${slideshowId}`,
        method: 'delete'
    })
}

export const requestSlideshowByName = async (slideshowName) => {
    return await axiosInstance.request({
        url: `/slideshow/name/${slideshowName}`,
        method: 'get'
    })
}

export const requestSlideshows = async (page, size) => {
    return await axiosInstance.request({
        url: '/slideshow',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const requestSlides = async (page, size) => {
    return await axiosInstance.request({
        url: '/slide',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const createSlide = async (slide) => {
    return await axiosInstance.request({
        url: '/slide',
        method: 'post',
        data: slide
    })
}

export const deleteSlide = async (slideId) => {
    return await axiosInstance.request({
        url: `/slide/${slideId}`,
        method: 'delete'
    })
}

export const sendEmail = async (email) => {
    return await axiosInstance.request({
        url: '/email/send/newsletter',
        method: 'post',
        data: email
    })
}

export const subscribe = async (payload) => {
    return await axiosInstance.request({
        url: '/email/subscribe',
        method: 'post',
        data: payload
    })
}

export const addNewEvent = async (payload) => {
    return await axiosInstance.request({
        url: '/event',
        method: 'post',
        data: payload
    })
}

export const editEventById = async (eventId, event) => {
    return await axiosInstance.request({
        url: `/event/${eventId}`,
        method: 'put',
        data: event
    })
}

export const fetchEventById = async (eventId) => {
    return await axiosInstance.request({
        url: `/event/${eventId}`,
        method: 'get'
    })
}

export const deleteEventById = async (eventId) => {
    return await axiosInstance.request({
        url: `/event/${eventId}`,
        method: 'delete'
    })
}

export const fetchAllEvents = async (page, size) => {
    return await axiosInstance.request({
        url: '/event',
        method: 'get',
        params: {
            page,
            size
        }
    })
}

export const liveLogin = async (code) => {
    return await axiosInstance.request({
        url: '/live/login',
        method: 'post',
        data: {
            code,
        }
    })
}

export const getLive = async (code, uuid) => {
    return await axiosInstance.request({
        url: '/live/event',
        method: 'post',
        data: {
            code,
            uuid,
        }
    })
}

export const uploadLiveFile = async (file) => {
    return await axiosInstance.request({
        headers: {
            'content-type': 'multipart/form-data'
        },
        url: '/live/file',
        method: 'post',
        data: file
    })
}
