import * as types from "./constants";

export const modalReducer = (state = {open: false, type: null, imgUrl: null, title: null}, {type, payload}) => {
    switch (type) {
        case types.OPEN_MODAL:
            return payload;
        case types.CLOSE_MODAL:
            return payload;
        default:
            return state;
    }
}