import { LOG_IN, LOG_OUT } from '../../constants/authentication'
import { authenticate, ping } from '../../utils/api'
import axiosInstance from '../../utils/axios'

export const login = (token) => {
    return { type: LOG_IN, payload: { authenticated: true, token }}
}

export const logout = () => {
    localStorage.removeItem('token')
    return { type: LOG_OUT, payload: { authenticated: false, token: null }}
}

export const setToken = (token) => {
    axiosInstance.defaults.headers = {
        Authorization: `Bearer ${token}`
    }
}

export function authenticateUser (values) {
    return async function (dispatch) {
        try {
            const response = await authenticate(values)
            const authHeader = response.headers.authorization
            const token = authHeader.split(' ')[1]
            localStorage.setItem('token', token)
            dispatch(login(token))
        } catch (error) {
            console.error(error)
            throw error
        }
    }
}

export function checkAuthentication () {
    return async function (dispatch) {
        const token = localStorage.getItem('token')
        if (token) {
            try {
                setToken(token)
                await ping()
                dispatch(login(token))
            } catch (error) {
                dispatch(logout())
                throw error
            }
        }
    }
}
