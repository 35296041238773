import React  from 'react'
import styled from 'styled-components'

import { selectLiveMenuAction } from '../../LiveForm/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PageContainer from '../../PageContainer'
import {PageTitle, Paragraph, Title} from '../../../components/Typography'
import FileInput from '../../../components/FileInput'
import { Field, Formik } from 'formik'
import Input from '../../../components/Input'

import { FormButtonContainer } from '../../../components/Form'
import { Button } from '../../../components/Buttons'
import * as Yup from 'yup'
import { uploadLiveFile } from '../../../utils/api'
import { LIVE_MENU_SELECTION_PAGE } from '../../LiveForm/constants'
import {flexbox} from "styled-system";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

const LiveMedia = ({ liveState, selectLiveMenuAction }) => {
    const { live = {}} = liveState
    const schema = Yup.object().shape({
        title: Yup.string(),
        fileData: Yup.string().required('Please select a file')
    })

    const getFileDataPayload = (data) => {
        const formData = new FormData()
        for (const key in data) {
            if (key === 'fileData') {
                for (const file of data[key]) {
                    formData.append(key, file)
                }
            } else {
                formData.append(key, data[key])
            }
        }
        const { live } = liveState
        console.log(live)
        formData.append('eventId', live.id)
        formData.append('code', live.code)
        formData.append('token', live.uuid)
        return formData
    }

    const uploadFile = async (values) => {
        try {
            const payload = getFileDataPayload(values)
            await uploadLiveFile(payload)
            selectLiveMenuAction(LIVE_MENU_SELECTION_PAGE)
        } catch (error) {
            console.error('Error retrieving event', error)
        }
    }

    const onSubmit = async (values) => {
        await uploadFile(values)
    }

    return (
        <PageContainer direction="column" styles={{
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <PageTitle>
                Share Photos
            </PageTitle>

            <Paragraph>
                Note: iPhone motion pictures are not supported at this time.
            </Paragraph>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    title: '',
                    fileData: ''
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
            >{({ handleSubmit, ...formikProps }) => (
                    <FormContainer
                        onSubmit={handleSubmit}
                    >
                        <Field
                            render={(props) => <Input {...props} name="title" label="Caption" />}
                            name="title"
                        />
                        <FileInput multiple limit={10} label="Select Files" name="fileData" {...formikProps} />
                        <FormButtonContainer>
                            <Button type="submit" disabled={!formikProps.isValid}>
                            Upload
                            </Button>
                        </FormButtonContainer>
                    </FormContainer>
                )}</Formik>
        </PageContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { selectLiveMenuAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LiveMedia))
