import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Field, Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../components/Checkbox'
import Input from '../../components/Input'
import PageContainer from '../PageContainer'
import Textarea from '../../components/Textarea'
import { Button } from '../../components/Buttons'
import { addEvent, editEvent, getEvent, deselectEvent } from './actions'
import { startLoading, stopLoading } from '../PageContainer/actions'
import { FormButtonContainer } from '../../components/Form'
import { PageTitle } from '../../components/Typography'
import FileSelector from '../../components/FileSelector'

const EventFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px;
`

const EventForm = (props) => {
    const { match, event, getEvent, addEvent, editEvent, startLoading, stopLoading, history, edit, deselectEvent } = props

    const getEventById = useCallback(async () => {
        const { history } = props

        try {
            startLoading()
            const eventId = match.params.eventId
            await getEvent(eventId)
            stopLoading()
        } catch (error) {
            console.error('Error retrieving event', error)
            stopLoading()
            history.push('/404')
        }
    }, [getEvent, startLoading, stopLoading])

    const editEventById = useCallback(async (data) => {
        try {
            startLoading()
            const eventId = match.params.eventId
            await editEvent(eventId, data)
            history.push(`/events/${eventId}/view`)
            stopLoading()
        } catch (error) {
            console.error('Error editing event', error)
            stopLoading()
        }
    }, [history, editEvent, startLoading, stopLoading])

    const createEvent = async (data) => {
        try {
            startLoading()
            const eventId = await addEvent(data)
            history.push(`/events/${eventId}/view`)
        } catch (error) {
            console.error('Error creating a new event', error)
            stopLoading()
        }
    }

    useEffect(() => {
        // Update the document title using the browser API
        if (edit && !event.title) {
            getEventById()
        }
    }, [edit, getEventById, event])

    const schema = Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string(),
        code: Yup.string().required(),
        startDate: Yup.date(),
        uuid: Yup.string(),
        eventBanner: Yup.string().url('Please input a valid url'),
        eventProgram: Yup.string().url('Please input a valid url'),
        eventSurvey: Yup.string().url('Please input a valid url'),
        active: Yup.bool(),
        raffle: Yup.bool(),
        files: Yup.array()
    })

    const newEvent = {
        title: '',
        description: '',
        code: '',
        startDate: '',
        uuid: '',
        eventBanner: '',
        eventProgram: '',
        eventSurvey: '',
        active: false,
        raffle: false,
        files: []
    }

    return (
        <PageContainer>
            <PageTitle>
                { edit ? 'Edit' : 'Create' } Event
            </PageTitle>
            <Formik
                enableReinitialize={true}
                initialValues={edit ? event : newEvent}
                validationSchema={schema}
                onSubmit={(values) => {
                    if (edit) {
                        editEventById(values)
                    } else {
                        createEvent(values)
                    }
                }}
                validateOnBlur={true}
            >{formikProps => {
                    console.log(formikProps)
                    return (
                        <EventFormContainer onSubmit={formikProps.handleSubmit}>
                            <Field
                                component={Input}
                                name="title"
                            />
                            <Field
                                component={Textarea}
                                name="description"
                            />
                            <Field
                                render={props => (<Input type="datetime-local" {...props} />)}
                                name="startDate"
                            />
                            <Field
                                component={Input}
                                name="code"
                            />
                            <Field
                                component={Input}
                                name="eventBanner"
                            />
                            <Field
                                component={Input}
                                name="eventSurvey"
                            />
                            <Field
                                component={Input}
                                name="eventProgram"
                            />
                            <Field
                                render={props => (<Input label="UUID" {...props} disabled/>)}
                                name="uuid"
                            />
                            <Field
                                component={Checkbox}
                                name="active"
                            />
                            <Field
                                component={Checkbox}
                                name="raffle"
                            />
                            <FileSelector onSelect={(values) => formikProps.setFieldValue('files', values)}
                                files={event.files}/>
                            <FormButtonContainer>
                                <Button type="submit" disabled={!formikProps.isValid}>
                                Save
                                </Button>
                            </FormButtonContainer>
                        </EventFormContainer>
                    )
                }
                }
            </Formik>
        </PageContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        event: state.event
    }
}

const mapDispatchToProps = { getEvent, editEvent, addEvent, startLoading, stopLoading, deselectEvent }

EventForm.propTypes = {
    edit: PropTypes.bool
}

EventForm.defaultProps = {
    event: {
        title: '',
        description: '',
        code: '',
        startDate: '',
        uuid: '',
        active: false
    },
    edit: false
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(EventForm)
)
