import React from 'react'
import styled from 'styled-components'

import PageContainer from '../PageContainer'
import { Field, Formik } from 'formik'
import Input from '../../components/Input'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {liveLoginAction, liveLoginFailure, liveLoginRequest, liveLoginSuccess} from './actions'
import * as Yup from 'yup'
import {FormButtonContainer} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {liveLogin} from "../../utils/api";
import Footer from "../Footer";

const LiveFormContainer = styled.form``

const LiveForm = ({ liveLoginSuccess, liveLoginFailure, history }) => {
    const submitCode = async ({ code }) => {
        try {
            const response = await liveLogin(code)
            const uuid = response.data
            liveLoginSuccess(uuid)
            history.push(`/live/${code}`);
        } catch (error) {
            liveLoginFailure()
            throw error
        }
    }

    const schema = Yup.object().shape({
        code: Yup.string().required('Please input a valid event code'),
    })

    return (
        <PageContainer
            styles={{
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    code: ''
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    if (values) {
                        submitCode(values)
                    }
                }}
                validateOnBlur={true}
            >{formikProps => (
                <LiveFormContainer onSubmit={formikProps.handleSubmit}>
                    <Field
                        render={(fieldProps) => <Input name="code" label="Event Code" {...fieldProps} />}
                        name="code"
                    />
                    <FormButtonContainer>
                        <Button type="submit" disabled={!formikProps.isValid}>
                            Submit
                        </Button>
                    </FormButtonContainer>
                </LiveFormContainer>
                )}
            </Formik>
        </PageContainer>
    )
}

const mapDispatchToProps = { liveLoginSuccess, liveLoginFailure }

export default connect(
    null,
    mapDispatchToProps
)(withRouter(LiveForm))
