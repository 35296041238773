import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchPosts } from '../../utils/api'
import { startLoading, stopLoading } from '../PageContainer/actions'
import { FEATURED_FEED } from './constants'
import PostFeed from './PostFeed'
import FeaturedFeed from './FeaturedFeed'

class Feed extends Component {
    constructor (props) {
        super(props)

        this.state = {
            page: 0,
            size: 100,
            posts: [],
            last: true
        }
    }

    componentDidMount () {
        this.getPosts()
    }

    setPosts (newPosts) {
        const { posts } = this.state
        this.setState({ posts: [...posts, ...newPosts]})
    }

    setLastAttribute (last) {
        this.setState({ last: last })
    }

    async getPosts () {
        const { startLoading, stopLoading } = this.props
        const { page, size } = this.state
        try {
            startLoading()
            const posts = await fetchPosts(page, size)
            this.setPosts(posts.data.content)
            this.setLastAttribute(posts.data.last)
            stopLoading()
        } catch (error) {
            console.error('Error retrieving posts', error)
            stopLoading()
        }
    }

    render () {
        const { type } = this.props
        const { posts, last } = this.state

        if (type === FEATURED_FEED) {
            return (
                <FeaturedFeed posts={posts} />
            )
        }
        return (
            <PostFeed posts={posts} last={last} />
        )
    }
}

Feed.propTypes = {
    type: PropTypes.string
}

const mapDispatchToProps = { startLoading, stopLoading }

export default connect(null, mapDispatchToProps)(Feed)
