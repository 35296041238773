import React from 'react'
import styled, { withTheme } from 'styled-components'
import {border, display, flexbox, layout, typography} from 'styled-system'
import { Fade } from 'react-reveal'

import { PageTitle, Title } from '../../../components/Typography'
import MasonryLayout from '../../MasonryLayout'
import { selectLiveMenuAction } from '../../LiveForm/actions'
import { connect } from 'react-redux'
import { LIVE_MENU_SELECTION_MEDIA } from '../../LiveForm/constants'
import { openModalAction } from '../../../components/Modal/actions'
import { MODAL_TYPE_FILE_VIEWER } from '../../../components/Modal/constants'
import { ReactComponent as CameraIcon} from "../../../images/camera.svg";
import { ReactComponent as TicketsIcon} from "../../../images/tickets.svg";

const LiveWelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.foreground};
  ${flexbox};
  ${border};
  
  border-top: 5px solid ${({ theme }) => theme.colors.primary};
  border-left: 5px solid ${({ theme }) => theme.colors.primary};
  border-right: 5px solid ${({ theme }) => theme.colors.primary};
`

const LiveWelcome = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const LiveBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LiveTitle = styled(PageTitle)`
    ${typography};
    ${display};
    padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[3]}px`};
`

const LivePageTitle = styled(LiveTitle)`
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[6]}px;
`

const LiveBanner = styled.div`
  cursor: pointer;
  background: url("${({ url }) => encodeURI(url)}");
  background-position: center;
  background-size: cover;
  ${layout};
  ${border};
  background-repeat: no-repeat;
`

const LiveProgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const LiveProgram = styled.img`
  margin: ${({ theme }) => `${theme.space[4]}px 0`};
  cursor: pointer;
  border: 5px solid ${({ theme }) => theme.colors.primary};
  height: auto;
  width: calc(100% - 10px);
  max-width: 700px;
`

const LiveShareMessage = styled.div`
  margin: ${({ theme }) => `${theme.space[5]}px 0`};
  cursor: pointer;
  align-items: center;
  transition: ease-in-out 1s;
  background-color: ${({ theme }) => theme.colors.foreground};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  
  :hover {
    transform: scale(1.05);
    color: ${({ theme }) => theme.colors.invertedText};
    background-color: ${({ theme }) => theme.colors.primary};

    > svg {
      fill: ${({ theme }) => theme.colors.invertedText};
    }
  }
`

const LiveLanding = ({ liveState, selectLiveMenuAction, openModalAction, theme }) => {
    const { live } = liveState
    return (
        <>
            <Fade>
                <LiveWelcomeContainer
                    flexDirection={['column', 'column', 'row']}
                    borderBottom={[`5px solid ${theme.colors.primary}`, `5px solid ${theme.colors.primary}`, `50px solid ${theme.colors.primary}`]}
                >
                    <LiveWelcome
                        flexDirection={['row', 'column', 'row']}
                    >
                        <LiveTitle
                            display={[
                                'none',
                                'none',
                                'flex',
                            ]}
                            fontSize={[
                                `${theme.fontSizes[2]}px`,
                                `${theme.fontSizes[3]}px`,
                                `${theme.fontSizes[4]}px`
                            ]}
                        >
                            Welcome!
                        </LiveTitle>
                        <LivePageTitle
                            fontSize={[
                                `${theme.fontSizes[4]}px`,
                                `${theme.fontSizes[5]}px`,
                                `${theme.fontSizes[6]}px`
                            ]}
                        >
                            <TicketsIcon style={{
                                transform: 'translate(10px, 10px)'
                            }} fill={theme.colors.text} width="50px" height="50px" /> {live.title}
                        </LivePageTitle>
                    </LiveWelcome>
                    <LiveBanner
                        borderColor={theme.colors.darkPrimary}
                        borderLeft={['unset', 'unset', `5px solid ${theme.colors.primary}`]}
                        borderTop={[`5px solid ${theme.colors.primary}`, `5px solid ${theme.colors.primary}`, 'unset']}
                        height={['200px', '300px', '400px']}
                        width={['100%', '100%', '50%']}
                        url={live.eventBanner}
                        onClick={() => openModalAction(MODAL_TYPE_FILE_VIEWER, live.eventBanner, live.title)}
                    />
                </LiveWelcomeContainer>
            </Fade>

            <LiveBody>
                {
                    live.eventProgram && (
                        <Fade>
                            <LiveProgramContainer>
                                <LivePageTitle
                                    fontSize={[
                                        `${theme.fontSizes[4]}px`,
                                        `${theme.fontSizes[5]}px`,
                                        `${theme.fontSizes[6]}px`
                                    ]}
                                >
                                    Event Program
                                </LivePageTitle>
                                <LiveProgram
                                    onClick={() => openModalAction(MODAL_TYPE_FILE_VIEWER, live.eventProgram, 'Event Program')}
                                    src={live.eventProgram}
                                />
                            </LiveProgramContainer>
                        </Fade>
                    )
                }

                <Fade>
                    <LiveShareMessage>
                        <LiveTitle onClick={() => selectLiveMenuAction(LIVE_MENU_SELECTION_MEDIA)}>
                            I want to see your photos!
                            <br />
                            <CameraIcon fill={theme.colors.text} width="75" height="75px" />
                            <br />
                            Click here to share them with everyone at the event.
                        </LiveTitle>
                    </LiveShareMessage>
                </Fade>

                {
                    live.files && live.files.length > 0 && (
                        <Fade>
                            <LiveTitle>
                                Shared Media:
                            </LiveTitle>
                            <MasonryLayout
                                columns={3}
                                items={live.files}
                            />
                        </Fade>
                    )
                }
            </LiveBody>
        </>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { selectLiveMenuAction, openModalAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(LiveLanding))
