import styled from 'styled-components'

export const LinkButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 20px;
    letter-spacing: ${({ theme }) => theme.space[0]}px;
    transition: 0.2s;
    border-bottom: 3px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    
    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
`
