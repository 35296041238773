import React from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
`;

export const LoadingScreen = (props) => {
    return (
       <LoadingContainer>
           Loading...
       </LoadingContainer>
    );
};