import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Table = styled.table`
  border-collapse: collapse;
`
const CellHeader = styled.th`
   padding: ${({ theme }) => theme.space[3]}px;
   text-align: left;
   border: none;
   font-size: 20px;
`
const Cell = styled.td`
  padding: ${({ theme }) => theme.space[3]}px;
  text-align: left;
  border: none;
`
const Row = styled.tr`
  margin: ${({ theme }) => `${theme.space[3]}px 0`};
  border-bottom: ${({ theme }) => `${theme.space[2]}px solid ${theme.colors.border}`};
  
  ${props => props.selectable && css`
    cursor: pointer;
    
    &:hover ${Cell} {
      background-color: ${({ theme }) => theme.colors.foreground};
      border-bottom: 5px solid ${({ theme }) => theme.colors.primary};
    }
  `}
`
const Thead = styled.thead``
const Tbody = styled.tbody``

class DataGrid extends React.Component {
    render () {
        const { columns, data, selectable, onRowClick } = this.props
        return (
            <Table>
                <Thead>
                    <tr>
                        {columns && columns.map(col => (<CellHeader key={col.label}>{col.label}</CellHeader>))}
                    </tr>
                </Thead>
                <Tbody>
                    { (columns && data) && data.map((_row, index) => {
                        return (
                            <Row key={`${_row}-${index}`} selectable={selectable} onClick={() => onRowClick(_row)}>
                                {columns.map((col, index) => (<Cell key={`${col.field}-${index}`}>{_row[col.field]}</Cell>))}
                            </Row>
                        )
                    })
                    }
                </Tbody>
            </Table>
        )
    }
}

DataGrid.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    selectable: PropTypes.bool,
    onRowClick: PropTypes.func
}

export default DataGrid
