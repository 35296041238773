import React, {Component} from "react";
import styled, {css} from "styled-components";
import { grid, layout, typography, space } from 'styled-system'
import PropTypes from 'prop-types'
import moment from "moment";
import { withRouter } from "react-router";

import ImageElement from "../../../components/ImageElement";

const PostContainer = styled.div`
    display: flex;
    position: relative;
    ${props => props.featured ? css`
        background-color: ${({ theme }) => theme.colors.foreground};
        box-shadow: 0 0 1px ${({ theme }) => theme.colors.backdrop};
    ` : null}
    padding: 10px;
    margin: ${({ theme }) => theme.space[4]}px 0;
    cursor: pointer;
    border-bottom: 10px ${({ theme }) => theme.colors.foreground} solid;
    justify-content: space-between;
    align-items: center;
    
    &:hover {
      transition: border-bottom .5s;
      border-bottom: 10px ${({ theme }) => theme.colors.primary} solid;
    }
  
    ${layout};
    ${space};
`;

const PostContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 2px;
  grid-template-columns: 100px  1fr;
  align-items: baseline;
  column-gap: 25px;

  ${space};
  ${grid};
`;
const PostTitle = styled.h3`
  display: flex;
  margin: 0;
  align-items: center;
  text-transform: uppercase;
  ${typography};
`;
const PostSubtitle = styled.p`
  display: flex;
  margin: 0;
  align-items: center;
  ${layout};
  ${typography};
`;

const Date = styled.span`
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-family: Exo;
  ${typography};
`;

const FeaturedRibbon = styled.div`
    display: flex;
    position: absolute;
    width: 150px;
    height: 20px;
    top: -20px;
    left: -15px;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 5px 15px;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: -1px -2px 2px ${({ theme }) => theme.colors.backdrop};
`;

const FeaturedRibbonFold = styled.div`
    position: absolute;
    left: -15px;
    width: 0;
    top: -5px;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid ${({ theme }) => theme.colors.darkPrimary};
    z-index: 0;
`;

class PostPreview extends Component {

    navigateToPost = () => {
        const { id, history } = this.props;
        history.push(`/post/${id}/view`);
    };

    render() {
        const {
            title,
            subtitle,
            createdAt,
            preview,
            youtubeVideoId,
            featured
        } = this.props;

        let previewImage = '';
        if (preview) {
            previewImage = preview;
        }
        else if (youtubeVideoId) {
            previewImage = `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`
        }
        const day = moment(createdAt).format('DD');
        const month = moment(createdAt).format('MMM');

        const thumbnailSizes = ['100px', '100px', '150px', '200px']

        return (
            <PostContainer
                pl={[3, 4, 5]}
                pr={[3, 4, 5]}
                featured={featured}
                onClick={() => this.navigateToPost()}>
                {featured && (
                    <>
                        <FeaturedRibbon>Featured</FeaturedRibbon>
                        <FeaturedRibbonFold />
                    </>
                )}
                <PostContent>
                    <Date fontSize={[5,5,5,6]}>
                        {month}
                    </Date>
                    <PostTitle fontSize={[2,2,3,4,4]}>
                        {title}
                    </PostTitle>
                    <Date fontSize={[5,5,5,6]}>
                        {day}
                    </Date>
                    <PostSubtitle fontSize={[2,2,2,3,3]}>
                        {subtitle}
                    </PostSubtitle>
                </PostContent>
                <ImageElement display={['none','none', 'flex']} src={previewImage} width={thumbnailSizes} height='auto' />
            </PostContainer>
        );
    }
}

PostPreview.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired
};

export default withRouter(PostPreview);