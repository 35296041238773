import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const Container = styled.div`
  ${space};
`

const SoundcloudElement = styled.div`
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
`

const Soundcloud = ({ soundCloudUrl }) => {
    return (
        <Container
            mt={[2,3,4]}
            mb={[2,3,4]}
        >
            <iframe
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                src={soundCloudUrl} />
            <SoundcloudElement>
            </SoundcloudElement>
        </Container>
    )
}

export default Soundcloud