import React from 'react'
import styled from 'styled-components'
// import { IconButton } from '../../../components/Buttons'
// import { ReactComponent as BackIcon } from '../../../images/back.svg'
// import { ReactComponent as LiveVibes } from '../../../images/live-vibes.svg'
import { selectLiveMenuAction } from '../../LiveForm/actions'
import * as types from '../../LiveForm/constants'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Item, NavButton, NavItem, NavPlaceholder} from './index'

const HeaderImage = styled.div`
  cursor: pointer;
`

const LiveHeader = ({ selectLiveMenuAction, history, liveState = {} }) => {
    const { live = {} } = liveState

    const goToEventHome = () => {
        selectLiveMenuAction(types.LIVE_MENU_SELECTION_PAGE)
        history.push(`/live/${live.code}`)
    }

    return Object.keys(live).length > 0 && (
        <>
            <NavButton
                inverted
                p={[1, 2, 3]}
                m={[1, 1, 2]}
                fontSize={[1, 2, 3]}
                onClick={goToEventHome}>
                Event Home
            </NavButton>
            |
            <NavButton
                inverted
                p={[1, 2, 3]}
                m={[0, 1, 2]}
                fontSize={[1, 2, 3]}
                onClick={() => selectLiveMenuAction(types.LIVE_MENU_SELECTION_MEDIA)}>
                Share
            </NavButton>
            {
                live.eventSurvey && (
                    <>
                        |
                        <NavButton
                            inverted
                            p={[1, 2, 3]}
                            m={[0, 1, 2]}
                            fontSize={[1, 2, 3]}
                            onClick={() => selectLiveMenuAction(types.LIVE_MENU_SELECTION_SURVEY)}>
                            Feedback
                        </NavButton>
                    </>
                )
            }
            {
                live.raffle && (
                    <>
                        |
                        <NavButton
                            inverted
                            p={[1, 2, 3]}
                            m={[0, 1, 2]}
                            fontSize={[1, 2, 3]}
                            onClick={() => selectLiveMenuAction(types.LIVE_MENU_SELECTION_RAFFLE)}>
                            Raffle
                        </NavButton>
                    </>
                )
            }
        </>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { selectLiveMenuAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LiveHeader))
