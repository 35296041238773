import * as types from './constants'
import { deletePostById, fetchPostById } from '../../utils/api'

export function getPostRequest (post) {
    return {
        type: types.GET_POST,
        payload: null
    }
}

export function getPostSuccess (post) {
    return {
        type: types.GET_POST_SUCCESS,
        payload: post
    }
}

export function getPostFailure () {
    return {
        type: types.GET_POST_FAILURE,
        payload: null
    }
}

export function getPost (postId) {
    return async function (dispatch) {
        try {
            dispatch(getPostRequest())
            const response = await fetchPostById(postId)
            const post = response.data
            dispatch(getPostSuccess(post))
            return post
        } catch (error) {
            console.error(error)
            dispatch(getPostFailure())
            throw error
        }
    }
}

export function deletePostRequest (post) {
    return {
        type: types.DELETE_POST,
        payload: null
    }
}

export function deletePostSuccess (post) {
    return {
        type: types.DELETE_POST_SUCCESS,
        payload: post
    }
}

export function deletePostFailure () {
    return {
        type: types.DELETE_POST_FAILURE,
        payload: null
    }
}

export function deletePost (postId) {
    return async function (dispatch) {
        try {
            dispatch(deletePostRequest())
            const response = await deletePostById(postId)
            const post = response.data
            dispatch(deletePostSuccess(post))
            return post
        } catch (error) {
            console.error(error)
            dispatch(deletePostFailure())
            throw error
        }
    }
}

export function deselectPost () {
    return {
        type: types.DESELECT_POST,
        payload: null
    }
}
