import React from 'react'
import ActionMenu, { ActionMenuButton } from './ActionMenu'
import { ReactComponent as TrashIcon } from '../../images/trashcan.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openModalAction } from '../Modal/actions'
import { MODAL_TYPE_DELETE_SLIDESHOW } from '../Modal/constants'
import { ReactComponent as AddIcon } from '../../images/add.svg'
import {withTheme} from "styled-components";

const FileActionMenu = ({ authentication, slideshow = {}, openModal, theme, ...props }) => {
    const fill = theme.colors.text
    const totalItems = authentication.authenticated ? 4 : 1

    const editFile = () => {
        const { history } = props
        history.push(`/slideshows/${slideshow.id}/edit`)
    }

    const addFile = () => {
        const { history } = props
        history.push('/slideshows/new')
    }

    return (
        <ActionMenu totalItems={totalItems}>
            { authentication.authenticated && (
                <>
                    {
                        slideshow && Object.keys(slideshow).length > 0 && (
                            <>
                                <ActionMenuButton title="Delete" onClick={() => openModal(MODAL_TYPE_DELETE_SLIDESHOW)}>
                                    <TrashIcon fill={fill} width="25px" height="25px" />
                                </ActionMenuButton>
                                <ActionMenuButton title="Edit" onClick={editFile}>
                                    <EditIcon fill={fill} width="25px" height="25px" />
                                </ActionMenuButton>
                            </>
                        )
                    }
                    <ActionMenuButton title="Create File" onClick={addFile}>
                        <AddIcon fill={fill} width="25px" height="25px" />
                    </ActionMenuButton>
                </>
            )
            }
        </ActionMenu>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
        file: state.file
    }
}

const mapDispatchToProps = { openModal: openModalAction }

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTheme(FileActionMenu))
)
