import * as types from './../EventForm/constants'

export const eventReducer = (state = {}, { type, payload }) => {
    switch (type) {
    case types.GET_EVENT_SUCCESS:
        return payload
    case types.GET_EVENT_FAILURE:
        return state
    default:
        return state
    }
}
