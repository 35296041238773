
export const DARK_GREY = '#292929'
export const MEDIUM_GREY = '#c7c7c7'
export const LIGHT_GREY = '#efefef'
export const TRANSPARENT_DARK_GREY = '#1b1b1bf5'
export const TRANSPARENT_LIGHT_GREY = '#070707b3'
export const RED = '#dc405d'
export const DARK_RED = '#a22e42'
export const PURPLE = '#ab3ffd'
export const DARK_PURPLE = '#752ca9'
export const BLUE = '#00a483'
export const DARK_BLUE = '#006456'
export const WHITE = '#fff'
export const BLACK = '#000'
