import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import { layout, space, position } from 'styled-system'
import { IconButton } from '../Buttons'
import { ReactComponent as ShareIcon } from '../../images/share.svg'
import { ReactComponent as DarkBulb } from '../../images/dark-bulb.svg'
import { ReactComponent as LightBulb } from '../../images/light-bulb.svg'
import { ReactComponent as Color } from '../../images/color.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openModalAction } from '../Modal/actions'
import { showAlert } from '../Alert/actions'
import { setDarkMode, setLightMode } from '../../containers/App/actions'
import { MODAL_TYPE_COLOR_PICKER } from '../Modal/constants'
import {userEvent} from "../../utils/analytics";

export const ActionMenuButton = styled(IconButton)`
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ disabled }) => disabled && css`pointer-events: none;`}
`

const ActionMenuContainer = styled.div`
  ${space};
  ${layout};
  ${position};
  flex-direction: column;
  position: fixed;
  right: 0;
  height: ${({ theme }) => theme.space[5]}px;
  transition: .5s height;
  border-left: ${({ theme }) => {
        return `${theme.colors.primary} ${theme.space[1]}px`
    }} solid;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.foreground};
  box-shadow: -5px 0 7px -2px ${({ theme }) => theme.colors.backdrop};
  justify-content: flex-end;
  overflow: hidden;
  &:hover {
    height: fit-content;
  }
`

const MobileActionMenuContainer = styled.div`
  ${layout};
  ${position};
  flex-direction: row-reverse;
  position: fixed;
  border-top: ${({ theme }) => `${theme.colors.primary} ${theme.space[1]}px`} solid;
  justify-content: space-between;
  box-shadow: -5px 0 7px -2px ${({ theme }) => theme.colors.backdrop};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.foreground};
`

const Container = styled.div`
  position: absolute;
  z-index: 999;
`

const ActionMenu = ({ children, showAlert, app, setDarkMode, setLightMode, theme, openModal }) => {
    const fill = theme.colors.text
    const copyUrl = () => {
        userEvent('Click', 'Copied URL')
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href)
            showAlert('URL Copied to Clipboard')
        } else {
            showAlert('Error copying link to clipboard')
        }
    }

    const isDarkMode = () => app.mode === 'dark'

    const toggleTheme = () => {
        userEvent('Click', 'Toggled Theme')
        if (isDarkMode()) {
            setLightMode()
            showAlert('Switched to Light Mode', 7000)
        } else if (!isDarkMode()) {
            setDarkMode()
            showAlert('Switched to Dark Mode', 7000)
        }
    }

    const openColorPicker = () => {
        userEvent('Click', 'Opened Color Picker')
        openModal(MODAL_TYPE_COLOR_PICKER)
    }

    return (
        <Container>
            <ActionMenuContainer
                display={['none', 'none', 'none', 'flex']}
                right="0"
                bottom={[5]}
            >
                {children}
                <ActionMenuButton title="Change Theme" onClick={toggleTheme}>
                    {
                        isDarkMode() ? <LightBulb title="Switch to Light Mode" fill={fill} width="50px" height="50px" /> : <DarkBulb title="Switch to Dark Mode" width="50px" height="50px" />
                    }
                </ActionMenuButton>
                <ActionMenuButton title="Change Color" onClick={openColorPicker}>
                    <Color title="Change Primary Color" fill={fill} width="50px" height="50px" />
                </ActionMenuButton>
                <ActionMenuButton title="Share" onClick={copyUrl}>
                    <ShareIcon title="Copy URL to Clipboard" fill={fill} width="40px" height="40px" />
                </ActionMenuButton>
            </ActionMenuContainer>

            <MobileActionMenuContainer
                display={['flex', 'flex', 'flex', 'none']}
                bottom="0"
                right="0"
                left="0"
            >
                {children}
                <ActionMenuButton title="Change Theme" onClick={toggleTheme}>
                    {
                        isDarkMode() ? <LightBulb title="Switch to Light Mode" fill={fill} width="50px" height="50px" /> : <DarkBulb title="Switch to Dark Mode" width="50px" height="50px" />
                    }
                </ActionMenuButton>
                <ActionMenuButton title="Change Color" onClick={openColorPicker}>
                    <Color title="Change Primary Color" fill={fill} width="50px" height="50px" />
                </ActionMenuButton>
                <ActionMenuButton title="Share" onClick={copyUrl}>
                    <ShareIcon title="Copy URL to Clipboard" fill={fill} width="50px" height="50px" />
                </ActionMenuButton>
            </MobileActionMenuContainer>
        </Container>
    )
}

const mapDispatchToProps = { openModal: openModalAction, showAlert, setDarkMode, setLightMode }

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
        app: state.app
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTheme(ActionMenu))
)
