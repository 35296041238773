import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { grid } from 'styled-system'
import PageContainer from '../PageContainer'
import PostPreview from './components/PostPreview'
import { Slide } from 'react-reveal'
import {small} from '../../constants/breakpoints'

const Container = styled.div`
    display: grid;
    
    ${grid};
  
    @media (min-width: ${small}) {
        grid-template-rows: max-content max-content;
    }
`

const FeaturedFeed = ({ posts }) => {
    const featuredPosts = posts.slice(0, 4)
    return (
        <PageContainer>
            <Container
                gridAutoFlow={['row']}
                gridGap={[1,1,2,4,5]}
            >
                {featuredPosts.map((post, i) => {
                    const revealAnimate = {}
                    const direction = `${i % 2 === 0 ? 'left' : 'right'}`
                    revealAnimate[direction] = true
                    return (
                        <Slide key={i} {...revealAnimate}>
                            <PostPreview width="50%" {...post} />
                        </Slide>
                    )
                })}
            </Container>
        </PageContainer>
    )
}

FeaturedFeed.propTypes = {
    posts: PropTypes.array.isRequired
}

export default FeaturedFeed
