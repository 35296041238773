import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Field, Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Checkbox from '../../components/Checkbox'
import Input from '../../components/Input'
import PageContainer from '../PageContainer'
import Textarea from '../../components/Textarea'
import { Button } from '../../components/Buttons'
import { addPost, editPost } from './actions'
import { deselectPost, getPost } from '../PostPage/actions'
import { startLoading, stopLoading } from '../PageContainer/actions'
import { FormButtonContainer } from '../../components/Form'
import { PageTitle } from '../../components/Typography'

const PostFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 20px;
`

const PostForm = (props) => {
    const { match, post, getPost, addPost, editPost, startLoading, stopLoading, history, edit, deselectPost } = props
    const postId = match.params.postId

    const getPostById = useCallback(async () => {
        const { history } = props

        try {
            startLoading()
            await getPost(postId)
            stopLoading()
        } catch (error) {
            console.error('Error retrieving post', error)
            stopLoading()
            history.push('/404')
        }
    }, [getPost, postId, props, startLoading, stopLoading])

    const editPostById = useCallback(async (data) => {
        try {
            startLoading()
            await editPost(postId, data)
            history.push(`/post/${postId}/view`)
            stopLoading()
        } catch (error) {
            console.error('Error editing post', error)
            stopLoading()
        }
    }, [history, postId, editPost, startLoading, stopLoading])

    const createPost = async (data) => {
        try {
            startLoading()
            const post = await addPost(data)
            history.push(`/post/${post.id}/view`)
        } catch (error) {
            console.error('Error creating a new post', error)
            stopLoading()
        }
    }

    useEffect(() => {
        // Update the document title using the browser API
        if (edit && !post.title) {
            getPostById()
        }
    }, [edit, getPostById, post])

    const schema = Yup.object().shape({
        title: Yup.string().required(),
        subtitle: Yup.string(),
        body: Yup.string(),
        youtubeVideoId: Yup.string(),
        preview: Yup.string().url().nullable(),
        soundCloudUrl: Yup.string().url().nullable(),
        featured: Yup.bool(),
        slideshowId: Yup.number().nullable()
    })

    const newPost = {
        title: '',
        subtitle: '',
        body: '',
        youtubeVideoId: '',
        preview: '',
        featured: false,
        slideshowId: null
    }

    return (
        <PageContainer>
            <PageTitle>
                { edit ? 'Edit' : 'Create' } Post
            </PageTitle>
            <Formik
                enableReinitialize={true}
                initialValues={edit ? post : newPost}
                validationSchema={schema}
                onSubmit={(values) => {
                    if (edit) {
                        editPostById(values)
                    } else {
                        createPost(values)
                    }
                }}
                validateOnBlur={true}
            >{formikProps => (
                    <PostFormContainer onSubmit={formikProps.handleSubmit}>
                        <Field
                            component={Input}
                            name="title"
                        />
                        <Field
                            component={Input}
                            name="subtitle"
                        />
                        <Field
                            render={ props => (<Input label="Youtube Video ID" {...props} />) }
                            name="youtubeVideoId"
                        />
                        <Field
                            render={ props => (<Input label="Soundcloud" {...props} />) }
                            name="soundCloudUrl"
                        />
                        <Field
                            component={Input}
                            name="preview"
                        />
                        <Field
                            render={ props => (<Input label="Slideshow ID" {...props} />) }
                            name="slideshowId"
                        />
                        <Field
                            component={Checkbox}
                            name="featured"
                        />
                        <Field
                            component={Textarea}
                            name="body"
                        />
                        <FormButtonContainer>
                            <Button type="submit" disabled={!formikProps.isValid}>
                                Save
                            </Button>
                        </FormButtonContainer>
                    </PostFormContainer>
                )
                }
            </Formik>
        </PageContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        post: state.post
    }
}

const mapDispatchToProps = { getPost, editPost, addPost, startLoading, stopLoading, deselectPost }

PostForm.propTypes = {
    edit: PropTypes.bool
}

PostForm.defaultProps = {
    post: {
        title: '',
        subtitle: '',
        body: '',
        youtubeVideoId: '',
        featured: false,
        preview: '',
        slideshowId: null
    },
    edit: false
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(PostForm)
)
