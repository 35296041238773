import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PostPreview from './components/PostPreview'
import PageContainer from '../PageContainer'
import { Button } from '../../components/Buttons'
import PostActionMenu from '../../components/ActionMenu/PostActionMenu'
import Footer from "../Footer";

const PostsContainer = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.text};
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.background};
    flex-grow: 1;
`

const SeeMoreButton = styled(Button)`
  width: 50%;
  align-self: center;
`

const NoResults = styled.div`
    display: flex;
    font-size: 28px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
`

const FeedHeader = styled.div`
    display: flex;
    justify-content: flex-end;
`

const PostFeed = ({ authentication, history, preview, last, posts }) => {
    const postElements = posts.map((post, i) => {
        return (<PostPreview key={i} {...post} />)
    })

    const DisplaySeeMoreButton = !last ? (
        <SeeMoreButton onClick={() => seeMoreClick()}>
            See more
        </SeeMoreButton>
    ) : null

    const seeMoreClick = () => {
        if (preview) {
            history.push('/posts')
        } else if (!last) {
            this.setState({ page: this.state.page + 1 }, this.getPosts)
        }
    }

    return (
        <>
            <PageContainer>
                <PostsContainer>
                    <FeedHeader>
                        {
                            authentication.authenticated && (
                                <div>u is admin</div>
                            )
                        }
                    </FeedHeader>
                    {
                        posts.length > 0
                            ? (
                                <>
                                    {postElements}
                                    {/*{DisplaySeeMoreButton}*/}
                                </>
                            ) : (
                                <NoResults>No results present</NoResults>
                            )
                    }
                </PostsContainer>
                <PostActionMenu />
            </PageContainer>
            <Footer />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
}

export default connect(mapStateToProps)(
    withRouter(PostFeed)
)
