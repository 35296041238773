import styled, { css } from 'styled-components'
import { flexbox, space } from 'styled-system'

const Button = styled.button`
    ${flexbox};
    ${space};
    display: flex;
    padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[3]}px`};;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};;
    font-size: 16px;
    border: hidden;
    border-radius: 2px;
    justify-content: center;
    cursor: pointer;
    background-color: ${({ theme, primary }) => primary ? theme.colors.primary : theme.colors.foreground};
    
    ${props => props.grow ? css`
      flex-grow: 1;      
    ` : null}
    
    &:hover {
        color: ${({ theme }) => theme.colors.invertedText};
        background-color: ${({ theme }) => theme.colors.primary};
    }

  &:disabled {
    cursor: not-allowed;

    ${({ theme, primary }) => {
      if (!primary) {
        return css`
              background-color: ${({ theme }) => theme.colors.foreground};
              color: ${theme.colors.disabled};
            `
      }
      return css`
          background-color: ${({ theme }) => theme.colors.primary};
        `
    }};
  }
`

Button.defaultProps = {
    flexGrow: 'unset'
}

export default Button
