export const LIVE_LOGIN = 'LIVE_LOGIN'
export const LIVE_LOGIN_SUCCESS = 'LIVE_LOGIN_SUCCESS'
export const LIVE_LOGIN_FAILURE = 'LIVE_LOGIN_FAILURE'

export const GET_LIVE_EVENT = 'GET_LIVE_EVENT'
export const GET_LIVE_EVENT_SUCCESS = 'GET_LIVE_EVENT_SUCCESS'
export const GET_LIVE_EVENT_FAILURE = 'GET_LIVE_EVENT_FAILURE'

export const SELECT_LIVE_MENU = 'SELECT_LIVE_MENU'

export const LIVE_MENU_SELECTION_PAGE = 'LIVE_MENU_SELECTION_PAGE'
export const LIVE_MENU_SELECTION_SURVEY = 'LIVE_MENU_SELECTION_SURVEY'
export const LIVE_MENU_SELECTION_RAFFLE = 'LIVE_MENU_SELECTION_RAFFLE'
export const LIVE_MENU_SELECTION_MEDIA = 'LIVE_MENU_SELECTION_MEDIA'
export const LIVE_MENU_SELECTION_TIP = 'LIVE_MENU_SELECTION_TIP'
