import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import styled, {withTheme} from 'styled-components'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import PageContainer from '../PageContainer'
import PostActionMenu from '../../components/ActionMenu/PostActionMenu'
import { Paragraph } from '../../components/Typography'
import { ReactComponent as AuthorIcon } from '../../images/author.svg'
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg'
import {deselectPost, getPost} from './actions'
import { startLoading, stopLoading } from '../PageContainer/actions'
import { large, xsmall } from '../../constants/breakpoints'
import Soundcloud from "../../components/Soundcloud";
import {pageView} from "../../utils/analytics";
import Footer from "../Footer";
import Slideshow from "../Slideshow";

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
`

const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.space[2]}px;
  margin: 0;
  border-bottom: ${({ theme }) => theme.colors.primary} 5px solid;
`

const PostTitle = styled.h2`
  letter-spacing: ${({ theme }) => theme.space[0]}px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bolder;
`

const PostSubtitle = styled.h3`
  display: flex;
  margin: 0px ${({ theme }) => theme.space[4]}px;
`

const PostMetadata = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.foreground};
  font-size: 14px;
`

const MetadataRow = styled.div`
  display: flex;
  margin: 5px 0;
`

const MetadataText = styled.div`
  display: flex;
  align-items: center;
`

const MetadataIcon = styled.div`
  display: flex;
  margin: 0 10px;
`

const MediaContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.space[4]}px 0;
  justify-content: center;
  align-self: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.foreground};
  height: 500px;
  max-width: 800px;
`

const SlideshowContainer = styled(MediaContainer)`
  height: 500px;
  max-width: 800px;

  @media (min-width: ${xsmall}) {
    width: 100%;
  }

  @media (min-width: ${large}) {
    width: 50vw;
  }
`

const VideoIframe = styled.iframe`
    height: 500px;
    max-width: 800px;

    @media (min-width: ${xsmall}) {
        width: 100%;
    }
    
    @media (min-width: ${large}) {
        width: 50vw;
    }
`

const PostPage = (props) => {
    const { post, match, theme, deselectPost, location } = props
    const fill = theme.colors.text
    const postId = match.params.postId

    const getPost = useCallback(async () => {
        const { startLoading, stopLoading, getPost, history } = props

        try {
            startLoading()
            await getPost(postId)
            stopLoading()
        } catch (error) {
            console.error('Error retrieving post', error)
            stopLoading()
            history.push('/404')
        }
    }, [postId])

    useEffect(() => {
        pageView(location.pathname);

        return () => {
            deselectPost()
        }
    }, [])

    useEffect(() => {
        // Update the document title using the browser API
        getPost()
    }, [getPost])

    if (!post) {
        return (
            <Redirect to="/404" />
        )
    }

    const { title, subtitle, body, youtubeVideoId, createdAt, soundCloudUrl, slideshowId, slideshow } = post
    const formattedDate = moment(createdAt).format('MMM D, YYYY')

    return (
        <>
            <PageContainer>
                <PostContainer>
                    <PostHeader>
                        <PostTitle>{title}</PostTitle>
                        <PostSubtitle> - {subtitle}</PostSubtitle>
                    </PostHeader>
                    <PostMetadata>
                        <MetadataRow>
                            <MetadataText>
                                <MetadataIcon>
                                    <AuthorIcon fill={fill} height="25px" width="25px" />
                                </MetadataIcon>
                                <MetadataText>
                                    Micah Prescott
                                </MetadataText>
                            </MetadataText>
                            <MetadataText>
                                <MetadataIcon>
                                    <CalendarIcon fill={fill} viewBox="0 0 100 100" height="25" width="25" />
                                </MetadataIcon>
                                <MetadataText>
                                    {formattedDate}
                                </MetadataText>
                            </MetadataText>
                        </MetadataRow>
                    </PostMetadata>
                    {
                        youtubeVideoId && (
                            <MediaContainer>
                                <VideoIframe
                                    src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    frameBorder='0'
                                    title='video'
                                />
                            </MediaContainer>
                        )
                    }

                    {/*{*/}
                    {/*    slideshowId && slideshow && (*/}
                    {/*        <SlideshowContainer>*/}
                    {/*            <Slideshow renderSlideshow={slideshow} />*/}
                    {/*        </SlideshowContainer>*/}
                    {/*    )*/}
                    {/*}*/}

                    {
                        soundCloudUrl && (
                            <Soundcloud
                                soundCloudUrl={soundCloudUrl}
                            />
                        )
                    }
                    <Paragraph>
                        {body}
                    </Paragraph>
                </PostContainer>
                <PostActionMenu />
            </PageContainer>
            <Footer />
        </>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
        post: state.post
    }
}

const mapDispatchToProps = { getPost, startLoading, stopLoading, deselectPost, deselectPost }

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTheme(PostPage))
)

PostPage.defaultProps = {
    post: {}
}
