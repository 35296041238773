import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { InputContainer, InputError, InputGroup, InputLabel } from '../Form'
import {layout, flexbox} from "styled-system";

export const InputElement = styled.input`
  display: flex;
  background-color: ${({ theme }) => theme.colors.foreground};
  color: ${({ theme }) => theme.colors.text};
  box-shadow: none;
  ${({ theme, primary }) => {
        if (primary) {
            return css`
                border-top: hidden;
                border-left: hidden;
                border-right: hidden;
                border-bottom: 5px solid ${theme.colors.primary}
                padding: ${({ theme }) => `${theme.space[2] - 2.5}px ${theme.space[3]}px`};
            `
        }
        return css`
          border: hidden;
          padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[3]}px`};
        `
    }};
  outline: none;
  font-size: 16px;

  ${({ grow }) => {
        if (grow) {
            return css`
                flex-grow: 1;
            `
        }
    }};
  
  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.disabled};
  }
  
  &:hover {
    box-shadow: 0px 0px 1px ${({ theme }) => theme.colors.backdrop};
  }
  ${layout};
`

const Input = ({
    label,
    field,
    grow = true,
    type = 'text',
    showError = true,
    hideLabel = false,
    form: { touched, errors },
    ...props
}) => (
    <InputContainer
        justifyContent={['center', 'center', 'flex-start']}
        flexDirection={['column', 'column', 'row']}
    >
        {!hideLabel && (
            <InputLabel
                width={['90%', '90%', 'unset']}
            >
                {label || field.name}
            </InputLabel>
        )}
        <InputGroup>
            <InputElement
                maxWidth={['90%', '90%', 7]}
                width={['90%', '90%', 'unset']}
                type={type}
                {...field}
                {...props}
            />
            { showError && touched[field.name] && errors[field.name] ? <InputError>{errors[field.name]}</InputError> : ' ' }
        </InputGroup>
    </InputContainer>
)

Input.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    hoverColor: PropTypes.string,
    label: PropTypes.string
}

Input.defaultProps = {
    type: 'text'
}

export default Input
