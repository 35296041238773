import ReactGA from 'react-ga'
import store from "../redux/store";

const getReduxState = () => {
    return  store.getState();
}

const shouldTrackAnalytics = () => {
    const state = getReduxState();
    const { authentication } = state;

    return !(authentication && authentication.authenticated);
}

const pageView = (url) => {
    if (shouldTrackAnalytics()) {
        ReactGA.pageview(url)
    }
}

const modalView = (modalUrl) => {
    if (shouldTrackAnalytics()) {
        ReactGA.modalview(modalUrl)
    }
}

const userEvent = (category, action, ...opts) => {
    if( shouldTrackAnalytics()) {
        ReactGA.event({
            category,
            action,
            ...opts
        })
    }
}

export {
    pageView,
    modalView,
    userEvent
}
