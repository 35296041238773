import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import PageContainer from '../PageContainer'
import { Field, Label, Value } from '../../components/Form'
import FileViewer from '../FileViewer'
import { PageTitle } from '../../components/Typography'
import SlideshowActionMenu from "../../components/ActionMenu/SlideshowActionMenu";
import {connect} from "react-redux";
import {getSingleSlideshowRequestById} from "../SlideshowForm/actions";

const ImageViewerGrid = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: max-content max-content;
    grid-gap: ${({ theme }) => theme.space[3]}px;
`

const SlideshowPage = ({ match, getSingleSlideshowRequestById, slideshow }) => {

    useEffect(() => {
        const id = match.params.slideshowId
        getSingleSlideshowRequestById(id)
    }, [])

    return (
        <PageContainer direction="column">
            {
                slideshow && (
                    <>
                        <PageTitle>
                            Slideshow
                        </PageTitle>
                        <Field>
                            <Label>
                                Name
                            </Label>
                            <Value>
                                {slideshow.name}
                            </Value>
                        </Field>
                        <Field flexDirection="column">
                            <Label>
                                Slides
                            </Label>
                            <ImageViewerGrid>
                                {
                                    slideshow.slides.map((slide, i) => (
                                        <Value>
                                            <FileViewer key={i} imgUrl={slide.location} />
                                        </Value>
                                    ))
                                }
                            </ImageViewerGrid>
                        </Field>
                    </>
                )
            }
            <SlideshowActionMenu slideshow={slideshow} />
        </PageContainer>
    )
}


const mapStateToProps = (state) => {
    return {
        slideshow: state.slideshow
    }
}

const mapDispatchToProps = { getSingleSlideshowRequestById }

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(SlideshowPage)
)

