import * as types from './constants'
import {addNewSlideshow, editSlideshowById, fetchSlideshowById as fetchSlideshow} from '../../utils/api'

export function editSlideshowRequest (slideshow) {
    return {
        type: types.EDIT_SLIDESHOW,
        payload: null
    }
}

export function editSlideshowSuccess (slideshow) {
    return {
        type: types.EDIT_SLIDESHOW_SUCCESS,
        payload: slideshow
    }
}

export function editSlideshowFailure () {
    return {
        type: types.EDIT_SLIDESHOW_FAILURE,
        payload: null
    }
}

export function editSlideshow (slideshowId, data) {
    return async function (dispatch) {
        try {
            dispatch(editSlideshowRequest())
            const response = await editSlideshowById(slideshowId, data)
            const slideshow = response.data
            dispatch(editSlideshowSuccess(slideshow))
            return slideshow
        } catch (error) {
            console.error(error)
            dispatch(editSlideshowFailure())
            throw error
        }
    }
}

export function addSlideshowRequest (slideshow) {
    return {
        type: types.ADD_SLIDESHOW,
        payload: null
    }
}

export function addSlideshowSuccess () {
    return {
        type: types.ADD_SLIDESHOW_SUCCESS,
        payload: null
    }
}

export function addSlideshowFailure () {
    return {
        type: types.ADD_SLIDESHOW_FAILURE,
        payload: null
    }
}

export function addSlideshow (data) {
    return async function (dispatch) {
        try {
            dispatch(addSlideshowRequest())
            const response = await addNewSlideshow(data)
            const slideshow = response.data
            dispatch(addSlideshowSuccess())
            return slideshow
        } catch (error) {
            console.error(error)
            dispatch(addSlideshowFailure())
            throw error
        }
    }
}

export function getSingleSlideshowRequest (post) {
    return {
        type: types.GET_SINGLE_SLIDESHOW,
        payload: null
    }
}

export function getSingleSlideshowRequestSuccess (post) {
    return {
        type: types.GET_SINGLE_SLIDESHOW_SUCCESS,
        payload: post
    }
}

export function getSingleSlideshowRequestFailure () {
    return {
        type: types.GET_SINGLE_SLIDESHOW_FAILURE,
        payload: null
    }
}

export function getSingleSlideshowRequestById (slideshowId) {
    return async function (dispatch) {
        try {
            dispatch(getSingleSlideshowRequest())
            const response = await fetchSlideshow(slideshowId)
            const post = response.data
            dispatch(getSingleSlideshowRequestSuccess(post))
            return post
        } catch (error) {
            dispatch(getSingleSlideshowRequestFailure())
            throw error
        }
    }
}
