import React from 'react'
import { Field, Formik } from 'formik'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import FileInput from '../../components/FileInput'
import { FormButtonContainer } from '../../components/Form'
import { Button } from '../../components/Buttons'
import styled from 'styled-components'
import { sendEmail } from '../../utils/api'
import { fetchFileAction } from '../FilePage/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { showAlert } from '../../components/Alert/actions'
import * as Yup from 'yup'
import PageContainer from '../PageContainer'

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

const SendEmailForm = ({ showAlert }) => {
    const onSubmit = async (values) => {
        try {
            await sendEmail(values)
            showAlert('Sent Email')
        } catch (error) {
            showAlert('Error sending email')
            console.error('Error sending email', error)
        }
    }

    const schema = Yup.object().shape({
        subject: Yup.string().required(),
        buttonText: Yup.string(),
        buttonLink: Yup.string(),
        imageUrl: Yup.string(),
        header: Yup.string(),
        preheader: Yup.string(),
        title: Yup.string().required(),
        subtitle: Yup.string()
    })

    return (
        <PageContainer>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    buttonText: 'Check it out!',
                    subject: 'itsMicah: New Update!',
                    preheader: ''
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
                validateOnBlur={true}
            >{({ handleSubmit, ...formikProps }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="subject"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="buttonText"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="buttonLink"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="imageUrl"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="title"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="subtitle"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="header"
                        />
                        <Field
                            render={ props => (<Input {...props} />) }
                            name="preheader"
                        />
                        <FormButtonContainer>
                            <Button type="submit" disabled={!formikProps.isValid}>
                            Send
                            </Button>
                        </FormButtonContainer>
                    </FormContainer>
                )}</Formik>
        </PageContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {}

const mapDispatchToProps = { showAlert }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendEmailForm)
