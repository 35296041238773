import React from 'react'
import PageContainer from "../PageContainer";
import Portfolio from "../Portfolio";

class LitePortfolio extends React.Component {
    render() {
        return (
            <PageContainer>
                <Portfolio />
            </PageContainer>
        );
    }
}

export default LitePortfolio;