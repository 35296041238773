import React from 'react'
import { IconButton } from '../Buttons'
import { ReactComponent as ArrowIcon } from '../../images/triple_right.svg'
import { Paragraph } from '../Typography'
import styled, {withTheme} from 'styled-components'
import {ReactComponent as RefreshIcon} from "../../images/refresh.svg";

const PaginationContainer = styled.div`
 display: flex;
  align-items: center;
  justify-content: space-between;
`

const PaginationMenu = styled.div`
  display: flex;
  align-items: center;
`

const Pagination = ({ page, prevPage, nextPage, refresh, theme }) => {
    const fill = theme.colors.text
    return (
        <PaginationContainer>
            <IconButton type="button" size="25px" onClick={() => prevPage()}>
                <ArrowIcon fill={fill} style={{ transform: 'rotate(180deg)' }} height="25px" width="25px" />
            </IconButton>
            <PaginationMenu>
                <Paragraph>
                    Page: {page + 1}
                </Paragraph>
                <IconButton type="button" size="25px" onClick={() => refresh()}>
                    <RefreshIcon fill={fill} height="25px" width="25px" />
                </IconButton>
            </PaginationMenu>
            <IconButton type="button" size="25px" onClick={() => nextPage()}>
                <ArrowIcon fill={fill} height="25px" width="25px" />
            </IconButton>
        </PaginationContainer>
    )
}

export default withTheme(Pagination)
