import React, { useEffect } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchLiveAction } from '../LiveForm/actions'
import Footer from '../Footer'
import * as types from '../LiveForm/constants'
import LiveMedia from './components/LiveMedia'
import PageContainer from '../PageContainer'
import LiveLanding from './components/LiveLanding'
import { ActionMenu } from '../../components/ActionMenu'
import LiveSurvey from "./components/LiveSurvey";

const LivePage = ({ fetchLiveAction, history, match, liveState }) => {
    const { menuSelection, live } = liveState

    const getLiveEvent = async () => {
        try {
            const liveCode = match.params.code
            await fetchLiveAction(liveCode)
        } catch (error) {
            console.error('Error fetching live event', error)
            history.push('/live')
        }
    }

    useEffect(() => {
        getLiveEvent()
    }, [])

    return (
        <>
            <PageContainer direction="column">
                { menuSelection === types.LIVE_MENU_SELECTION_PAGE && (<LiveLanding />) }
                { menuSelection === types.LIVE_MENU_SELECTION_SURVEY && (<LiveSurvey />) }
                { menuSelection === types.LIVE_MENU_SELECTION_MEDIA && (<LiveMedia />) }
            </PageContainer>
            <ActionMenu />
            <Footer />
        </>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { fetchLiveAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LivePage))
