import * as types from './constants'

export const alertReducer = (state = {}, { type, payload }) => {
    switch (type) {
    case types.SHOW_ALERT:
        return payload
    case types.HIDE_ALERT:
        return payload
    default:
        return {
            message: '',
            visible: false,
            duration: 5000
        }
    }
}
