import styled from 'styled-components'
import { layout } from 'styled-system'
import PropTypes from 'prop-types'

export const IconButton = styled.button`
    ${layout}
    
    display: flex;
    cursor: pointer;
    background: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
`

IconButton.propTypes = {
    size: PropTypes.string
}

IconButton.defaultProps = {
    width: '64px',
    height: '64px'
}
