import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import PageContainer from "../PageContainer";
import {fetchFileAction} from "./actions";
import FileViewer from "../FileViewer";
import FileActionMenu from "../../components/ActionMenu/FileActionMenu";
import {Field, Label, Value} from "../../components/Form";
import {PageTitle} from "../../components/Typography";

const Thumbnail = styled(Field)`
  cursor: pointer;
`;

class FilePage extends React.Component {

    componentDidMount() {
        this.fetchFile();
    }

    fetchFile = async () => {
        const { fetchFileAction, match } = this.props;
        const fileId = match.params.fileId;
        try {
            await fetchFileAction(fileId);
        } catch(error) {
            console.error("Error fetching file by id",  error);
        }
    }

    render() {
        const { file } = this.props;
        return (
            <PageContainer direction="column">
                <PageTitle>
                    File
                </PageTitle>
                <Field>
                    <Label>
                        Name
                    </Label>
                    <Value>
                        {file.name}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Type
                    </Label>
                    <Value>
                        {file.type}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Description
                    </Label>
                    <Value>
                        {file.description}
                    </Value>
                </Field>

                <Field>
                    <Label>
                        Location
                    </Label>
                    <Value>
                        {file.location}
                    </Value>
                </Field>

                {
                    file && (
                        <Thumbnail>
                            <FileViewer imgUrl={file.path} type={file.type} />
                        </Thumbnail>
                    )
                }
                <FileActionMenu />
            </PageContainer>
        );
    }
}

FilePage.propTypes = {
    fileId: PropTypes.number
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        file: state.file
    }
};

const mapDispatchToProps = { fetchFileAction };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FilePage));
