import React from 'react'
import styled from 'styled-components'
import { Button } from '../Buttons'
import { withRouter } from 'react-router-dom'
import ImageElement from '../ImageElement'
import { ModalButtons, ModalContainer, ModalTitle } from '../../styles/Modal'

const ModalImageElement = styled(ImageElement)`
  object-fit: contain;
  padding-bottom: 25px;
`

const FileViewerModal = ({ closeModal, name, imgUrl }) => {
    return (
        <ModalContainer
            p={[2, 3, 4]}
            width={[0.9, 0.8, 0.7, 1 / 2]}
        >
            <ModalTitle>
                {name || imgUrl}
            </ModalTitle>
            <ModalImageElement
                height="40vh"
                src={imgUrl}
            />
            <ModalButtons>
                <Button onClick={closeModal}>
                    Close
                </Button>
            </ModalButtons>
        </ModalContainer>
    )
}

export default withRouter(FileViewerModal)
