import React from 'react'
import styled from 'styled-components'
import { space, typography } from 'styled-system'

import Hero from '../../components/Hero'
import Feed from '../Feed'
import { FEATURED_FEED } from '../Feed/constants'

import { ActionMenu } from '../../components/ActionMenu'
import { connect } from 'react-redux'
import Footer from "../Footer";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 160vh;
`

const FeedTitleContainer = styled.div`
  ${space};
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.banner};
`;

const FeedTitle = styled.h2`
  justify-content: center;
  align-items: center;
  color: ${({ theme, mode }) => mode === 'dark' ? theme.colors.text : theme.colors.invertedText};
  display: flex;
  font-size: 30px;
  margin: 0;
  text-transform: uppercase;
  
  ${space};
  ${typography};
`

function Home ({ app }) {
    return (
        <HomeContainer>
            <Hero />
            <FeedTitleContainer
                pt={[4]}
                pb={[4]}
            >
                <FeedTitle
                    mode={app.mode}
                    fontSize={[2, 3, 4]}
                >
                    Latest and Greatest
                </FeedTitle>
            </FeedTitleContainer>
            <Feed type={FEATURED_FEED} />
            <Footer />
            <ActionMenu />
        </HomeContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        app: state.app
    }
}

const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)
