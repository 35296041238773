import React from 'react'
import styled from 'styled-components'

import PageContainer from '../PageContainer'
import PropTypes from 'prop-types'
import { fetchFileAction } from '../FilePage/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, Formik } from 'formik'
import Input from '../../components/Input'
import * as Yup from 'yup'
import { createFile, editFileById } from '../../utils/api'
import { FormButtonContainer } from '../../components/Form'
import { Button } from '../../components/Buttons'
import Textarea from '../../components/Textarea'
import FileInput from '../../components/FileInput'
import {PageTitle} from "../../components/Typography";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

class FileForm extends React.Component {
    componentDidMount () {
        this.fetchFile()
    }

    async fetchFile () {
        const { fetchFileAction, match } = this.props
        const fileId = match.params.fileId
        try {
            await fetchFileAction(fileId)
        } catch (error) {
            console.error(`Error fetching file by id ${fileId}`, error)
        }
    }

    async editFileById (data) {
        const { match, history } = this.props
        const fileId = match.params.fileId
        try {
            await editFileById(fileId, data)
            history.push(`/files/${fileId}/view`)
        } catch (error) {
            console.error(`Error editing file by id ${fileId}`, error)
        }
    }

    async createFile (data) {
        const { history } = this.props
        const payload = this.getFileDataPayload(data)
        try {
            const response = await createFile(payload)
            const fileId = response.data
            history.push(`/files/${fileId}/view`)
        } catch (error) {
            console.error('Error creating file', error)
        }
    }

    getFileDataPayload (data) {
        const formData = new FormData()
        for (const key in data) {
            formData.append(key, data[key])
        }
        return formData
    }

    render () {
        const { file, edit } = this.props

        const newFile = {
            title: '',
            description: '',
            fileData: ''
        }

        const schema = Yup.object().shape({
            title: Yup.string().required(),
            description: Yup.string()
        })

        if (!edit) {
            schema.fileData = Yup.string().required()
        }

        return (
            <PageContainer direction="column">
                <PageTitle>
                    { edit ? 'Edit' : 'Create' } File
                </PageTitle>
                <Formik
                    enableReinitialize={true}
                    initialValues={edit ? file : newFile}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        if (edit) {
                            this.editFileById(values)
                        } else {
                            this.createFile(values)
                        }
                    }}
                    validateOnBlur={true}
                >{({ handleSubmit, ...formikProps }) => (
                        <FormContainer onSubmit={handleSubmit}>
                            <Field
                                render={ props => (<Input label="File Name" {...props} disabled />) }
                                name="fileName"
                            />
                            <Field
                                render={ props => (<Input {...props} />) }
                                name="title"
                            />
                            <Field
                                render={ props => (<Input {...props} disabled />) }
                                name="type"
                            />
                            <Field
                                render={ props => (<Input {...props} disabled />) }
                                name="path"
                            />
                            {
                                !edit && (
                                    <>
                                        <Field
                                            render={ props => (<Textarea {...props} />) }
                                            name="description"
                                        />
                                        <FileInput name="fileData" {...formikProps} />
                                    </>
                                )
                            }
                            <FormButtonContainer>
                                <Button type="submit" disabled={!formikProps.isValid}>
                                    Save
                                </Button>
                            </FormButtonContainer>
                        </FormContainer>
                    )}</Formik>
            </PageContainer>
        )
    }
}

FileForm.propTypes = {
    edit: PropTypes.bool
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        file: state.file
    }
}

const mapDispatchToProps = { fetchFileAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FileForm))
