import * as types from './constants'
import { deleteSlideshowById, fetchSlideshowById } from '../../utils/api'

export function getSlideshowRequest (slideshow) {
    return {
        type: types.GET_SLIDESHOW,
        payload: null
    }
}

export function getSlideshowSuccess (slideshow) {
    return {
        type: types.GET_SLIDESHOW_SUCCESS,
        payload: slideshow
    }
}

export function getSlideshowFailure () {
    return {
        type: types.GET_SLIDESHOW_FAILURE,
        payload: null
    }
}

export function getSlideshow (slideshowId) {
    return async function (dispatch) {
        try {
            dispatch(getSlideshowRequest())
            const response = await fetchSlideshowById(slideshowId)
            const slideshow = response.data
            dispatch(getSlideshowSuccess(slideshow))
            return slideshow
        } catch (error) {
            console.error(error)
            dispatch(getSlideshowFailure())
            throw error
        }
    }
}

export function deleteSlideshowRequest (slideshow) {
    return {
        type: types.DELETE_SLIDESHOW,
        payload: null
    }
}

export function deleteSlideshowSuccess (slideshow) {
    return {
        type: types.DELETE_SLIDESHOW_SUCCESS,
        payload: slideshow
    }
}

export function deleteSlideshowFailure () {
    return {
        type: types.DELETE_SLIDESHOW_FAILURE,
        payload: null
    }
}

export function deleteSlideshow (slideshowId) {
    return async function (dispatch) {
        try {
            dispatch(deleteSlideshowRequest())
            const response = await deleteSlideshowById(slideshowId)
            const slideshow = response.data
            dispatch(deleteSlideshowSuccess(slideshow))
            return slideshow
        } catch (error) {
            console.error(error)
            dispatch(deleteSlideshowFailure())
            throw error
        }
    }
}
