import React, { useRef, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { InputContainer, InputError, InputGroup, InputLabel } from '../Form'
import { ReactComponent as CloudUploadIcon } from '../../images/cloud-upload.svg'
import { InputElement } from '../Input'
import { layout } from 'styled-system'

const FileUploadElement = styled.label`
    display: flex;
    background-color: ${({ theme }) => theme.colors.foreground};
    padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[3]}px`};
    align-items: center;
    cursor: pointer;
    ${layout};
`

const FileInputElement = styled(InputElement)`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

const FileUploadIcon = styled.div`
  margin-right: 30px;
`

const FileInput = ({ name, label, errors, touched, onChange, setFieldTouched, setFieldValue, setErrors, multiple, theme, limit = 10, ...props }) => {
    const [selected, setSelected] = useState()

    const onChangeEvent = (e) => {
        const fileValues = e.target.files
        if (fileValues && fileValues.length > limit) {
            setErrors({ [name]: `You can only upload ${limit} files at a time.` })
        } else {
            if (multiple) {
                setFieldValue(name, fileValues)
                setSelected(fileValues)
            } else {
                setFieldValue(name, fileValues[0])
                setSelected(fileValues[0])
            }
        }
    }

    const getMessage = () => {
        if (!selected) {
            return 'Click to share photo'
        }

        if (multiple) {
            return `${selected.length} file(s) selected for upload`
        } else {
            return `${selected.name} is selected for upload`
        }
    }

    const message = getMessage()
    const inputProps = {}

    if (multiple) {
        inputProps.multiple = 'multiple'
    }

    return (
        <InputContainer
            justifyContent={['center', 'center', 'flex-start']}
            flexDirection={['column', 'column', 'row']}
            width={['90%', '90%']}
        >
            <InputLabel
                width={['90%', '90%', 'unset']}
            >
                {label || name}
            </InputLabel>
            <InputGroup>
                <FileUploadElement
                    maxWidth={['90%', '90%', 7]}
                    width={['90%', '90%', 'unset']}
                    htmlFor="upload-file">
                    <FileUploadIcon>
                        <CloudUploadIcon fill={theme.colors.text} width="50px"/>
                    </FileUploadIcon>
                    {message}
                </FileUploadElement>
                <FileInputElement id="upload-file" type="file" {...inputProps} onChange={onChangeEvent} onBlur={() => setFieldTouched(name)} {...props} />
                <InputError>{ touched[name] && errors[name] ? errors[name] : ' ' }</InputError>
            </InputGroup>
        </InputContainer>
    )
}

export default withTheme(FileInput)
