export const ADD_EVENT = 'ADD_EVENT'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE'

export const EDIT_EVENT = 'EDIT_EVENT'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE'

export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE'

export const DESELECT_EVENT = 'DESELECT_EVENT'
