import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { PageTitle, Paragraph, Title } from '../Typography'
import { deleteSlide, requestSlides } from '../../utils/api'
import { IconButton } from '../Buttons'
import { ReactComponent as RefreshIcon } from '../../images/refresh.svg'
import { ReactComponent as ArrowIcon } from '../../images/triple_right.svg'
import Pagination from "../Pagination";
import {getSlideshow} from "../../containers/SlideshowPage/actions";
import {addSlideshow, editSlideshow, getSingleSlideshowRequestById} from "../../containers/SlideshowForm/actions";
import {startLoading, stopLoading} from "../../containers/PageContainer/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const SlideSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: ${({ theme }) => theme.space[5]}px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
`

const DeleteSlideButton = styled(PageTitle)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bolder;
`

const Preview = styled.div`
  display: flex;
  position: relative;
  background: ${({ url }) => `url(${encodeURI(url)})`};
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${({ theme }) => theme.space[1]}px;
  border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  ${({ selected }) => selected ? css`
    border: ${({ theme }) => theme.space[1]}px solid ${({ theme }) => theme.colors.primary};
  ` : css`
    border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
  `}
  
  &:hover {
    border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.primary};
    box-shadow: inset 0 0 100px ${({ theme }) => theme.colors.backdrop};
  }

  &:hover ${DeleteSlideButton} {
    display: flex;
  }
`

const SlideSelector = ({ openModal, onSelect, slideshow }) => {
    const [selectedSlides, setSelectedSlides] = useState([])
    const [slides, setSlides] = useState([])
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(5)

    useEffect(() => {
        let isMounted = true;
        fetchSlides()
        return () => { isMounted = false };
    }, [page])

    useEffect(() => {
        if (slideshow) {
            setSelectedSlides(slideshow.slideIds)
        }
    }, [slideshow])

    const fetchSlides = async () => {
        try {
            const response = await requestSlides(page, size)
            setSlides(response.data.content)
        } catch (error) {
            console.error('Error fetching slides', error)
        }
    }

    const onDeleteClick = async (slideId) => {
        try {
            await deleteSlide(slideId)
            await fetchSlides()
        } catch (error) {
            console.error('Error deleting slide by id', error)
        }
    }

    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const selectSlide = (slide) => {
        const index = selectedSlides.indexOf(slide.id) > -1
        console.log('SLIDE', index, selectedSlides)
        if (index) {
            const newSelection = selectedSlides.splice(index, 1)
            setSelectedSlides(newSelection)
            onSelect(newSelection)
        } else {
            const newSelection = [...selectedSlides, slide.id]
            setSelectedSlides(newSelection)
            onSelect(newSelection)
        }
    }

    return (
        <SlideSelectorContainer>
            <Header>
                <Title>
                    Slides
                </Title>
            </Header>
            <Pagination refresh={() => fetchSlides()} page={page} prevPage={prevPage} nextPage={nextPage} />
            <PreviewContainer>
                { slides ? slides.map(slide => {
                    const isSelected = selectedSlides ? selectedSlides.indexOf(slide.id) > -1 : false
                    return (
                        <Preview selected={isSelected} key={`slide-${slide.id}`} url={slide.location} onClick={() => selectSlide(slide)}>
                            <DeleteSlideButton type="button" onClick={() => onDeleteClick(slide.id)}>
                                    X
                            </DeleteSlideButton>
                        </Preview>
                    )
                }) : 'No slides' }
            </PreviewContainer>
        </SlideSelectorContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        slideshow: state.slideshow
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(
    SlideSelector
)
