import React from 'react'
import ActionMenu, { ActionMenuButton } from './ActionMenu'
import { ReactComponent as TrashIcon } from '../../images/trashcan.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openModalAction } from '../Modal/actions'
import { MODAL_TYPE_DELETE_FILE } from '../Modal/constants'
import { ReactComponent as AddIcon } from '../../images/add.svg'
import {withTheme} from "styled-components";

const EventActionMenu = ({ authentication, event, openModal, theme, ...props }) => {
    const fill = theme.colors.text
    const totalItems = authentication.authenticated ? 5 : 2

    const editEvent = () => {
        const { history } = props
        history.push(`/events/${event.id}/edit`)
    }

    const addEvent = () => {
        const { history } = props
        history.push('/events/new')
    }

    return (
        <ActionMenu totalItems={totalItems}>
            { authentication.authenticated && (
                <>
                    {
                        event && Object.keys(event).length && (
                            <>
                                <ActionMenuButton title="Delete" onClick={() => openModal(MODAL_TYPE_DELETE_FILE)}>
                                    <TrashIcon fill={fill} width="25px" height="25px" />
                                </ActionMenuButton>
                                <ActionMenuButton title="Edit" onClick={editEvent}>
                                    <EditIcon fill={fill} width="25px" height="25px" />
                                </ActionMenuButton>
                            </>
                        )
                    }
                    <ActionMenuButton title="Create Event" onClick={addEvent}>
                        <AddIcon fill={fill} width="25px" height="25px" />
                    </ActionMenuButton>
                </>
            )
            }
        </ActionMenu>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
        event: state.event
    }
}

const mapDispatchToProps = { openModal: openModalAction }

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTheme(EventActionMenu))
)
