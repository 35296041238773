import React from "react";
import styled from "styled-components";

import PageContainer from "../PageContainer";

const NotFoundText = styled.h2`
  display: flex;
`;

const PageNotFound = (props) => {
    return (
        <PageContainer>
            <NotFoundText>
                The page you are looking for cannot be found.
            </NotFoundText>
        </PageContainer>
    );
};

export default PageNotFound;