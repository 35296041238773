import React, { useEffect, useState } from 'react'
import PageContainer from '../PageContainer'
import { requestSlideshows } from '../../utils/api'
import DataGrid from '../../components/DataGrid'
import { withRouter } from 'react-router-dom'
import {PageTitle} from "../../components/Typography";
import SlideshowActionMenu from "../../components/ActionMenu/SlideshowActionMenu";

const Slideshows = (props) => {
    const [slideshows, setSlideshows] = useState([])

    const appendSlideshows = (newSlideshows) => {
        setSlideshows(slideshows.concat(newSlideshows))
    }

    const fetchSlideshows = async (page = 0, size = 10) => {
        try {
            const response = await requestSlideshows(page, size)
            appendSlideshows(response.data.content)
            if (!response.data.last) {
                fetchSlideshows(page + 1, size)
            }
        } catch (error) {
            console.error('Error fetching files', error)
        }
    }

    useEffect(() => {
        fetchSlideshows()
    }, [])

    const navigateToSlideshow = (slideshow) => {
        const { history } = props
        history.push(`/slideshows/${slideshow.id}/view`)
    }

    const columns = [
        { label: 'ID', field: 'id' },
        { label: 'Name', field: 'name' }
    ]

    return (
        <PageContainer>
            <PageTitle>
                Slideshows
            </PageTitle>
            <DataGrid columns={columns} selectable data={slideshows} onRowClick={navigateToSlideshow} />
            <SlideshowActionMenu />
        </PageContainer>
    )
}

export default withRouter(Slideshows)
