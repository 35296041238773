import * as types from './constants'

const defaultState = {
    live: {},
    menuSelection: types.LIVE_MENU_SELECTION_PAGE
}

export const liveReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
    case types.GET_LIVE_EVENT_SUCCESS:
        return {
            ...state,
            live: payload
        }
    case types.SELECT_LIVE_MENU:
        return {
            ...state,
            menuSelection: payload
        }
    case types.GET_LIVE_EVENT_FAILURE:
        return defaultState
    default:
        return state
    }
}
