import React from 'react'
import { HexColorPicker } from 'react-colorful'

const ColorPicker = ({ initialValue, ...props }) => {
    const onChange = (color) => {
        props.setFieldValue('color', color)
    }

    return (
        <HexColorPicker height="100px" color={initialValue} onChange={onChange} />
    )
}

export default ColorPicker
