import React from 'react'
import styled, { withTheme } from 'styled-components'
import { selectLiveMenuAction } from '../../LiveForm/actions'
import { openModalAction } from '../../../components/Modal/actions'
import { connect } from 'react-redux'
import { PageTitle } from '../../../components/Typography'

const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300vh;
`

const LiveSurvey = ({ liveState = {}}) => {
    const { live } = liveState
    return (
        <SurveyContainer>
            <PageTitle>
                Feedback
            </PageTitle>
            <iframe
                src={live.eventSurvey}
                width="100%"
                height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
            </iframe>
        </SurveyContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        liveState: state.live
    }
}

const mapDispatchToProps = { selectLiveMenuAction, openModalAction }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(LiveSurvey))
