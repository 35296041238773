import React from 'react';
import PropTypes from "prop-types";
import styled, { css } from 'styled-components';

const Slide = ({ name, location, link, slideHeight }) => {

    const SlideContainer = styled.div`
      display: flex;    
      align-items: center;
      justify-content: center;
      height: inherit;
      flex-shrink: 0;
      background-image: url("${location}");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: ${window.innerWidth}px; 
      ${link && css`
        cursor: pointer;
      `}
    `;

    const onSlideClick = () => {
        if (link) {
            window.open(link, '_blank')
        }
    }

    return (
        <SlideContainer onClick={onSlideClick} data-src={location}/>
    );
};

Slide.propTypes = {
    name: PropTypes.string,
    location: PropTypes.string.isRequired
};

export default Slide;