import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { layout, position, space, typography } from 'styled-system'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import logo from '../../images/logo.png'
import blueLogo from '../../images/logo-blues.png'
import { logout } from '../Login/actions'
import { checkTheme, setDarkMode, setLightMode } from '../App/actions'
import Alert from '../../components/Alert'
import { even } from '../../utils'
import { userEvent } from '../../utils/analytics'
import LiveHeader from './components/LiveHeader'
import { NavButton, NavDropdown, NavDropdownOptions, NavDropdownOption, NavItem } from './components'

const FocusedCss = css`
    height: 100px;
`

const UnfocusedCss = css`
    height: 50px;
`

const zoomIn = keyframes`
    0% {
        ${UnfocusedCss}
    }
    100% {
        ${FocusedCss}
    }
`

const zoomOut = keyframes`
    0% {
        ${FocusedCss}
    }
    100% {
        ${UnfocusedCss}
    }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
`

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: .5s forwards ${({ topScroll = true }) => topScroll ? zoomIn : zoomOut};
    color: ${({ theme }) => theme.colors.invertedText};
    background-color: ${({ theme }) => theme.colors.banner};
    text-transform: uppercase;
    text-decoration: none;
    font-family: Quicksand-Bold, SansSerif, serif;
    ${space};
`

const SubheaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand-Bold, SansSerif, serif;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: inset 0 0 3px ${({ theme }) => theme.colors.banner};
  ${space};
`

const Nav = styled.div`
  display: flex;
`

const logoLarge = keyframes`
    0% {
        height: 50px;
        width: 50px;
    }
    100% {
      height: 75px;
      width: 75px;
    }
`

const logoSmall = keyframes`
    0% {
      height: 75px;
      width: 75px;
    }
    100% {
      height: 50px;
      width: 50px;
    }
`

const Logo = styled.div`
  background-image: url("${even ? logo : blueLogo}");
  animation: .5s forwards ${({ topScroll = true }) => topScroll ? logoLarge : logoSmall};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  ${layout};
`

const Header = (props) => {
    const { app, checkTheme, setDarkMode, setLightMode, authentication, logout, history, topScroll, lite = false } = props
    const [visible, setVisibility] = useState(false)
    const toggleVisibility = () => setVisibility(!visible)

    const navigate = (url, element) => {
        userEvent('Click', `Clicked Header Button: ${element}`)
        history.push(url)
    }

    const recordEvent = (element) => {
        userEvent('Click', `Clicked Header Button: ${element}`)
    }

    const navigateHome = () => {
        navigate('/', 'Home Logo')
    }

    useEffect(() => {
        if (!app || !app.mode) {
            checkTheme()
        } else {
            if (app.mode === 'light') {
                setDarkMode()
            } else if (app.mode === 'dark') {
                setLightMode()
            }
        }
    }, [])

    return lite ? (
        <HeaderContainer>
            it&#39;s micah
        </HeaderContainer>
    ) : (
        <Container>
            <HeaderContainer
                topScroll={topScroll}
                pl={[3, 4, 5, 6, 6]}
                pr={[3, 4, 5, 6, 6]}
            >
                <Logo
                    topScroll={topScroll}
                    onClick={() => navigateHome()}
                />
                <Nav>
                    <NavItem
                        onClick={() => recordEvent('Posts')}
                        to="/posts"
                        p={[1, 2, 3]}
                        m={[1, 2]}
                        fontSize={[1, 2, 3]}
                    >
                    Posts
                    </NavItem>
                    <NavItem
                        onClick={() => recordEvent('Portfolio')}
                        to="/portfolio"
                        p={[1, 2, 3]}
                        m={[1, 2]}
                        fontSize={[1, 2, 3]}
                    >
                    Portfolio
                    </NavItem>
                    {
                        authentication.authenticated ? (
                            <NavDropdown onMouseEnter={toggleVisibility} onMouseLeave={toggleVisibility}>
                                <NavButton
                                    onClick={() => recordEvent('Admin')}
                                    p={[1, 2, 3]}
                                    m={[1, 2]}
                                    fontSize={[1, 2, 3]}
                                >
                                Admin
                                </NavButton>
                                { visible ? <NavDropdownOptions top={[4, 5]} >
                                    <NavDropdownOption
                                        fontSize={[1, 2, 3]}
                                        onClick={() => navigate('/emails', 'Email Button')}
                                    >
                                    Emails
                                    </NavDropdownOption>
                                    <NavDropdownOption
                                        fontSize={[1, 2, 3]}
                                        onClick={() => navigate('/events', 'Event Button')}
                                    >
                                        Event
                                    </NavDropdownOption>
                                    <NavDropdownOption
                                        fontSize={[1, 2, 3]}
                                        onClick={() => navigate('/files', 'Files Button')}
                                    >
                                    Files
                                    </NavDropdownOption>
                                    <NavDropdownOption
                                        fontSize={[1, 2, 3]}
                                        onClick={() => navigate('/slideshows', 'Slideshow Button')}
                                    >
                                    Slideshows
                                    </NavDropdownOption>
                                    <NavDropdownOption
                                        fontSize={[1, 2, 3]}
                                        onClick={() => logout()}
                                    >
                                    Logout
                                    </NavDropdownOption>
                                </NavDropdownOptions> : null}
                            </NavDropdown>
                        ) : null
                    }
                </Nav>
            </HeaderContainer>
            <SubheaderContainer
                pl={[3, 4, 5, 6, 6]}
                pr={[3, 4, 5, 6, 6]}
            >
                <LiveHeader />
            </SubheaderContainer>
            <Alert />
        </Container>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
    }
}

const mapDispatchToProps = { logout, setDarkMode, setLightMode, checkTheme }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Header))
