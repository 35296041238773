import {
    DARK_GREY,
    LIGHT_GREY,
    MEDIUM_GREY,
    RED,
    PURPLE,
    BLUE,
    TRANSPARENT_DARK_GREY,
    TRANSPARENT_LIGHT_GREY,
    DARK_RED,
    DARK_BLUE,
    DARK_PURPLE
} from '../constants/colors'

import { createGlobalStyle } from 'styled-components'
import { even, thirds } from '../utils'

export const GlobalStyles = createGlobalStyle`
    body {
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.text};
        overflow-x: hidden;
        -moz-transition: all .2s ease;
        -o-transition: all .2s ease;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
    }
`

export const getDefaultPrimary = even ? RED : thirds ? BLUE : PURPLE
export const getDefaultDarkPrimary = even ? DARK_RED : thirds ? DARK_BLUE : DARK_PURPLE

export const lightMode = {
    backdrop: TRANSPARENT_LIGHT_GREY,
    background: 'white',
    banner: TRANSPARENT_DARK_GREY,
    border: MEDIUM_GREY,
    disabled: '#cecece',
    error: RED,
    foreground: LIGHT_GREY,
    invertedText: LIGHT_GREY,
    primary: getDefaultPrimary,
    darkPrimary: getDefaultDarkPrimary,
    text: 'black'
}

export const darkMode = {
    backdrop: TRANSPARENT_LIGHT_GREY,
    background: DARK_GREY,
    banner: TRANSPARENT_DARK_GREY,
    border: MEDIUM_GREY,
    disabled: '#cecece',
    error: RED,
    foreground: TRANSPARENT_DARK_GREY,
    invertedText: DARK_GREY,
    primary: getDefaultPrimary,
    darkPrimary: getDefaultDarkPrimary,
    text: 'white'
}
