import React from 'react'
import { connect } from 'react-redux'
import { closeModalAction, openModalAction } from './actions'
import {
    MODAL_TYPE_COLOR_PICKER,
    MODAL_TYPE_DELETE_FILE,
    MODAL_TYPE_DELETE_POST,
    MODAL_TYPE_DELETE_SLIDESHOW,
    MODAL_TYPE_FILE_VIEWER
} from './constants'
import FileViewerModal from './FileViewerModal'
import { ModalBackDrop } from '../../styles/Modal'
import DeleteModal from './DeleteModal'
import ColorPickerModal from './ColorPickerModal'

const Modal = ({ modal, ...props }) => {
    let ModalContent
    switch (modal.type) {
    case (MODAL_TYPE_DELETE_POST):
        ModalContent = (
            <DeleteModal
                type={modal.type}
                title="Delete Post"
                {...props}
            />
        )
        break
    case (MODAL_TYPE_DELETE_FILE):
        ModalContent = (
            <DeleteModal
                type={modal.type}
                title="Delete File"
                {...props}
            />
        )
        break
    case (MODAL_TYPE_DELETE_SLIDESHOW):
        ModalContent = (
            <DeleteModal
                type={modal.type}
                title="Delete Slideshow"
                {...props}
            />
        )
        break
    case (MODAL_TYPE_COLOR_PICKER):
        ModalContent = (
            <ColorPickerModal
                type={modal.type}
                {...props}
            />
        )
        break
    case (MODAL_TYPE_FILE_VIEWER):
        ModalContent = (<FileViewerModal imgUrl={modal.imgUrl} name={modal.title} {...props} />)
        break
    default:
        console.log('no modal')
    }

    return modal.open ? (
        <ModalBackDrop>
            {ModalContent}
        </ModalBackDrop>
    ) : null
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = { openModal: openModalAction, closeModal: closeModalAction }

export default connect(mapStateToProps, mapDispatchToProps)(
    Modal
)
