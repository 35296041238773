import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import PageContainer from '../PageContainer'

import * as breakpoints from '../../constants/breakpoints'
import MasonryBlock from "./components/MasonryBlock";

const MasonryColumn = styled.div`
    flex: 1;
    margin-left: ${({ marginLeft }) => marginLeft}px;
`

const MasonryLayout = ({ theme, gap, items = []}) => {
    const [columns, setColumns] = useState(2)
    const [eventListener, setEventListener] = useState()
    const breakpointsMap = {
        [breakpoints.xlarge]: 5,
        [breakpoints.large]: 4,
        [breakpoints.medium]: 3,
        [breakpoints.small]: 3,
        [breakpoints.xsmall]: 2
    }

    const getColumns = () => {
        if (theme) {
            for (const size of Object.keys(breakpointsMap)) {
                const match = window.matchMedia(`(min-width: ${size})`).matches
                if (match) {
                    setColumns(breakpointsMap[size])
                    break
                }
            }
        }
    }

    const getEventListener = () => {
        if (!eventListener) {
            const listener = window.addEventListener('resize', getColumns)
            setEventListener(listener)
        }
    }

    useEffect(() => {
        getColumns()
        getEventListener()
    }, [styled], () => {
        window.removeEventListener('resize', eventListener)
    })

    const columnWrapper = {}
    const layout = []

    const getHeight = () => {
        return 200 + Math.ceil(Math.random() * 300)
    }

    const fileElements = items.map((file) => {
        const height = getHeight()
        const third = height / 3
        const half = height / 2
        const fourth = height / 4

        return (
            <MasonryBlock
                {...file}
                height={[fourth, fourth, third, third, height, height, height]}
                width={['150px', '200px', '250px']}
            />
        )
    })

    // create columns
    for (let i = 0; i < columns; i++) {
        columnWrapper[`column${i}`] = []
    }

    // divide children into columns
    for (let i = 0; i < fileElements.length; i++) {
        const columnIndex = i % columns
        columnWrapper[`column${columnIndex}`].push(
            <div style={{ marginBottom: `${gap}px` }}>
                {fileElements[i]}
            </div>
        )
    }

    let max = 0;
    const rowTotals = {}
    Object.keys(columnWrapper).forEach(wrapper => {
        const total = columnWrapper[wrapper].length

        if (total > max) {
            max = total
        }

        rowTotals[wrapper] = total
    });

    for (const key in rowTotals) {
        const total = rowTotals[key];
        if (total < max) {
            const delta = max - total
            const result = [ ...Array(delta) ].map(() => {
                const height = getHeight()
                const third = height / 3
                const half = height / 2
                const fourth = height / 4

                const blankElement = (
                    <MasonryBlock
                        height={[fourth, fourth, third, third, height, height, height]}
                        width={['150px', '200px', '250px']}
                    />
                );


                columnWrapper[key].push(blankElement)
            })
        }
    }

    // wrap children in each column with a div
    for (let i = 0; i < columns; i++) {
        layout.push(
            <MasonryColumn
                marginLeft={i > 0 ? gap : 0}>
                {columnWrapper[`column${i}`]}
            </MasonryColumn>
        )
    }

    return (
        <PageContainer direction="row">
            {layout}
        </PageContainer>
    )
}

MasonryLayout.defaultProps = {
    columns: 2,
    gap: 20
}

export default withTheme(MasonryLayout)
