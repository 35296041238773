import * as types from './constants'

export const appReducer = (state, { type, payload }) => {
    switch (type) {
    case types.CHECK_COLOR:
        return { ...state }
    case types.RESET_COLOR:
    case types.CHANGE_COLOR:
        return {
            ...state,
            color: payload
        }
    case types.UNSUBSCRIBE:
    case types.SUBSCRIBE:
        return {
            ...state,
            subscribed: payload
        }
    case types.TOGGLE_THEME:
        return {
            ...state,
            mode: payload
        }
    default:
        return state || { mode: '', subscribed: 'false', color: '' }
    }
}
