import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { PageTitle } from '../Typography'
import {connect} from "react-redux";

const Container = styled.div`
  display: flex;
`

const DeleteSlideButton = styled(PageTitle)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bolder;
`

const Preview = styled.div`
  display: flex;
  position: relative;
  background: ${({ url }) => `url(${encodeURI(url)})`};
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${({ theme }) => theme.space[1]}px;
  border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  ${({ selected }) => selected ? css`
    border: ${({ theme }) => theme.space[1]}px solid ${({ theme }) => theme.colors.primary};
  ` : css`
    border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.border};
  `}
  
  &:hover {
    border: ${({ theme }) => theme.space[0]}px solid ${({ theme }) => theme.colors.primary};
    box-shadow: inset 0 0 100px ${({ theme }) => theme.colors.backdrop};
  }

  &:hover ${DeleteSlideButton} {
    display: flex;
  }
`

const FileSelector = ({ onSelect, files = [] }) => {
    const [selected, setSelected] = useState([])

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (selected && JSON.stringify(selected) !== JSON.stringify(files)) {
            setSelected(files)
        }
    }, [files])

    const selectFile = (file) => {
        const index = selected.indexOf(file) > -1
        console.log(index)
        const newSelection = index ? selected.splice(index, 1) : [...selected, file]
        setSelected(newSelection)
        onSelect(newSelection)
    }

    return (
        <Container>
            {files.map((file) => (
                <Preview
                    selected={selected.includes(file)}
                    onClick={() => selectFile(file)}
                    url={file.path}
                />
            ))}
        </Container>
    )
}

export default FileSelector
