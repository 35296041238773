import { combineReducers } from 'redux'
import { alertReducer } from '../components/Alert/reducer'
import { loginReducer } from '../containers/Login/reducer'
import { loading } from '../containers/PageContainer/reducer'
import { postReducer } from '../containers/PostPage/reducer'
import { eventReducer } from '../containers/EventPage/reducer'
import { modalReducer } from '../components/Modal/reducer'
import { slideshowReducer } from '../containers/Slideshow/reducer'
import { fileReducer } from '../containers/FilePage/reducer'
import { singleSlideshowReducer } from '../containers/SlideshowForm/reducer'
import { appReducer } from '../containers/App/reducer'
import { liveReducer } from '../containers/LiveForm/reducer'

const rootReducer = combineReducers({
    alert: alertReducer,
    app: appReducer,
    authentication: loginReducer,
    event: eventReducer,
    live: liveReducer,
    file: fileReducer,
    loading: loading,
    modal: modalReducer,
    post: postReducer,
    slideshow: singleSlideshowReducer,
    slideshows: slideshowReducer
})

export default rootReducer
