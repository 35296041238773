import styled from 'styled-components'
import {flexbox, layout} from 'styled-system'

export const Field = styled.div`
    margin: ${({ theme }) => theme.space[2]}px;
    ${flexbox}
`
export const Label = styled.div`
  min-width: ${({ theme }) => theme.space[6]}px;
`
export const Value = styled.div`
  min-width: ${({ theme }) => theme.space[6]}px;
`

export const InputContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.space[4]}px 0;
  flex-grow: 1;
  align-items: center;
  ${flexbox};
`

export const InputError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  text-transform: capitalize;
  margin-top: ${({ theme }) => theme.space[2]}px;
`

export const InputLabel = styled.label`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: bold;
  min-width: ${({ theme }) => theme.space[7]}px;
  ${layout};
`

export const InputGroup = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
flex-grow: 1;
  width: 100%;
`

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.space[5]}px 0;
`
