import * as types from "./constants";

export const singleSlideshowReducer = (state = { slides: [], slideIds: [] }, {type, payload}) => {
    switch (type) {
        case types.GET_SINGLE_SLIDESHOW_SUCCESS:
            return payload;
        case types.GET_SINGLE_SLIDESHOW_FAILURE:
            return state;
        default:
            return state;
    }
};