import React, {useEffect} from 'react'
import { ThemeProvider } from 'styled-components'
import { darkMode, GlobalStyles, lightMode } from '../../styles'

import { connect } from 'react-redux'
import {checkPrimaryColor} from "../App/actions";

const Theme = ({ children, app, checkPrimaryColor }) => {
    const mode = app.mode === 'dark' ? darkMode : lightMode
    mode.primary = app && app.color && app.color.length > 0 ? app.color : mode.primary

    const theme = {
        fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
        sizes: [12, 24, 48, 96, 144, 192, 384, 768],
        lineHeights: ['14px', '16px', '18px', '22px', '26px', '38px', '50px', '82px', '98px'],
        breakpoints: [
            '400px',
            '720px',
            '1024px',
            '1440px',
            '1920px'
        ],
        space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
        colors: mode
    }

    useEffect(() => {
        checkPrimaryColor()
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    )
}

const mapDispatchToProps = {
    checkPrimaryColor
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        app: state.app
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    Theme
)
