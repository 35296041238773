import * as types from "./constants";

export const loading = (state, {type, payload}) => {
    switch (type) {
        case types.LOADING:
            return state + 1;
        case types.LOADED:
            return state - 1;
        default:
            return 0;
    }
}