import React from 'react'
import styled from 'styled-components'
import { space, display, flexbox, layout } from 'styled-system'
import PageContainer from '../PageContainer'
import AvatarImage from '../../images/micah_headshot_smaller.jpg'
import { Paragraph } from '../../components/Typography'

import { LinkButton } from '../../components/Buttons'
import {ActionMenu} from "../../components/ActionMenu";
import SocialLinks from "../../components/SocialLinks";
import Footer from "../Footer";

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexbox};
  position: relative;
  overflow: hidden;
`

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${space};
`

const Avatar = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-image: url("${AvatarImage}");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
`

const Bio = styled(Paragraph)`
  display: flex;
  flex-grow: 1;
`

const VerticalDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  ${display};
  ${layout};
`

const Metadata = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
`

const Portfolio = () => {
    const navigateToLinkedIn = () => {
        window.open('https://www.linkedin.com/in/micah-prescott-23388311b/')
    }

    const viewResume = () => {
        window.open('https://drive.google.com/file/d/18NqnUtVKb0z5zRAb1iZ0zobOywBWsiwt/view')
    }

    const viewGithub = () => {
        window.open('https://github.com/itsMicah')
    }

    return (
        <>
            <PageContainer direction="column">
                <ProfileContainer flexWrap={['wrap', 'wrap', 'wrap', 'wrap', 'nowrap']}>
                    <Profile m={[3, 4]}>
                        <Avatar />
                        <Metadata>
                            <LinkButton title="View Resume" onClick={viewResume}>
                                Resume
                            </LinkButton>
                        </Metadata>
                        <SocialLinks />
                    </Profile>
                    {/*<VerticalDivider display={['none', 'none', 'none', 'none', 'flex']} />*/}
                    <VerticalDivider
                        minWidth={['100%', '100%', '100%', '100%', '10px']}
                        height={['10px', '10px', '10px', '10px', '400px']}
                    />
                    <Profile m={[3, 4]}>
                        <Bio>
                            Hello there,
                            <br />
                            <br />
                            My name is Micah Prescott. Welcome to my portfolio website for all things vibraphone and web development.
                            I have over eight years experience in web development in both Quality Engineer and Software Engineer roles.
                            Plus, I have a background in percussion performance and film scoring.
                            Overall, I love being creative and finding ways to combine my two interests of music and coding.
                            <br />
                            <br />
                            I leveraged the skills of my software engineer experience to create a custom website to host my music portfolio and career resume.
                            Fun fact, the website is built with React and Spring Boot.
                            <br />
                            <br />
                            Check out my LinkedIn and Resume for more information about my experience.
                        </Bio>
                    </Profile>
                    <ActionMenu />
                </ProfileContainer>
            </PageContainer>
            <Footer />
        </>
    )
}

export default Portfolio
