import * as types from './constants'

export function closeModalAction () {
    return {
        type: types.CLOSE_MODAL,
        payload: {
            open: false,
            type: null
        }
    }
}

export function openModalAction (type, imgUrl, title) {
    return {
        type: types.OPEN_MODAL,
        payload: {
            open: true,
            type: type,
            imgUrl,
            title,
        }
    }
}
