import * as types from "../../constants/authentication";

export const loginReducer = (state = {}, {type, payload}) => {
    switch (type) {
        case types.LOG_IN:
            return payload;
        case types.LOG_OUT:
            return payload;
        default:
            return state;
    }
};