import styled, {css} from 'styled-components'

export const PageTitle = styled.h2`
    padding:0;
    margin: 0;
    letter-spacing: ${({ theme }) => theme.space[0]}px;
    ${({ theme, primary }) => {
        if (primary) {
            return css`
              color: ${theme.colors.primary};
            `
        }
    }}
`

export const Title = styled.h3`
  padding: 0;
  margin: 0;
  letter-spacing: ${({ theme }) => theme.space[0]}px;
`

export const Paragraph = styled.p`
    padding:0;
    margin: 0;
    line-height: 40px;
    font-size: 20px;
    letter-spacing: ${({ theme }) => theme.space[0]}px;
    word-break: break-word;
    white-space: pre-wrap;
`