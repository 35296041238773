import axios from 'axios'
import { BASE_URL, HTTP_TIMEOUT } from '../constants/env'
import store from '../redux/store'

const checkForToken = () => {
  return localStorage.getItem('token')
}

const clearToken = () => {
  const token = checkForToken()
  if (token) {
    localStorage.clear()
  }
}

const getHeaders = () => {
  const token = checkForToken()
  const authorization = token ? `Bearer ${token}` : null
  return {
    Authorization: authorization
  }
}

export const getAuthInstance = () => {
  const newInstance = axios.create({
    baseURL: BASE_URL,
    timeout: HTTP_TIMEOUT,
    headers: getHeaders()
  })

  newInstance.interceptors.request.use(function (config) {
    const token = store.getState().authentication.token || sessionStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  newInstance.interceptors.response.use(function (response) {
    const statusCode = response.status
    if (statusCode === 401) {
      clearToken()
    }
    return response
  })

  return newInstance
}

const axiosInstance = getAuthInstance()

export default axiosInstance
