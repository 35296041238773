import React from 'react'
import styled from 'styled-components'
import { border, space } from 'styled-system'

import SocialLinks from '../../components/SocialLinks'

const Container = styled.div`
  ${space};
  ${border};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-top: 10px solid ${({ theme }) => theme.colors.border};
`

const FooterDetails = styled.div`
  display: flex;
  text-align: center;
  margin: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`

const Anchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  
  &:visited, &:focus {
    color: ${({ theme }) => theme.colors.text};
  }
`

const Footer = () => {
    return (
        <Container
            borderWidths={[1,2,3]}
            paddingTop={[0, 0, 5]}
            paddingBottom={[6, 5]}
        >
            <SocialLinks />
            <FooterDetails>
                © itsMicah, © Micerbeats 2021
                <br />
                Have a question or notice an issue? Contact me via a social network.
            </FooterDetails>
        </Container>

    )
}

export default Footer
