import * as types from './constants'

export function hideAlert () {
    return {
        type: types.HIDE_ALERT,
        payload: {
            message: '',
            visible: false
        }
    }
}

export function showAlert (message, duration) {
    return {
        type: types.SHOW_ALERT,
        payload: {
            message,
            visible: true,
            duration: duration
        }
    }
}
