import React from "react";
import * as Yup from 'yup';
import styled from "styled-components";
import { Formik, Field } from 'formik';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import Input from "../../components/Input";
import {Button} from "../../components/Buttons";
import {authenticateUser} from "./actions";


const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
`;

const LoginForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
`;


const Login = (props) => {
    let schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required()
    });
    const { history, authenticate } = props;

    const loginAsUser = async (values) => {
        try {
            await authenticate(values);
            history.push("/");
        } catch (error) {
            console.error("Error authenticating");
        }
    };

    return (
        <LoginContainer>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={schema}
                onSubmit={loginAsUser}
                validateOnBlur={true}
                >{formikProps => (
                        <LoginForm onSubmit={formikProps.handleSubmit}>
                            <Field
                                component={Input}
                                type="email"
                                name="email"
                            />
                            <Field
                                component={Input}
                                type="password"
                                name="password"
                            />
                            <Button type="submit">Submit</Button>
                        </LoginForm>
                    )
                }
            </Formik>
        </LoginContainer>
    );
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        authentication: state.authentication
    }
};

const mapDispatchToProps = { authenticate: authenticateUser };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));

