import React from 'react'
import ActionMenu, { ActionMenuButton } from './ActionMenu'
import { ReactComponent as TrashIcon } from '../../images/trashcan.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openModalAction } from '../Modal/actions'
import { MODAL_TYPE_DELETE_POST } from '../Modal/constants'
import { ReactComponent as AddIcon } from '../../images/add.svg'
import { withTheme } from 'styled-components'

const PostActionMenu = ({ authentication, post, openModal, theme, ...props }) => {
    const fill = theme.colors.text
    const addPost = () => {
        const { history } = props
        history.push('/post/new')
    }

    const editPost = () => {
        const { history } = props
        history.push(`/post/${post.id}/edit`)
    }

    return (
        <ActionMenu>
            {
                authentication.authenticated && post && Object.keys(post).length > 0 && (
                    <>
                        <ActionMenuButton title="Delete" onClick={() => openModal(MODAL_TYPE_DELETE_POST)}>
                            <TrashIcon fill={fill} width="25px" height="25px"/>
                        </ActionMenuButton>
                        <ActionMenuButton title="Edit" onClick={editPost}>
                            <EditIcon fill={fill} width="25px" height="25px"/>
                        </ActionMenuButton>
                    </>
                )
            }
            {
                authentication.authenticated && (
                    <ActionMenuButton title="Create Post" onClick={addPost}>
                        <AddIcon fill={fill} width="25px" height="25px" />
                    </ActionMenuButton>
                )
            }
        </ActionMenu>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        authentication: state.authentication,
        post: state.post
    }
}

const mapDispatchToProps = { openModal: openModalAction }

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withTheme(PostActionMenu))
)
