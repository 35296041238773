import React from "react";
import styled from "styled-components";
import {openModalAction} from "../../components/Modal/actions";
import {connect} from "react-redux";
import {MODAL_TYPE_FILE_VIEWER} from "../../components/Modal/constants";
import ImageElement from "../../components/ImageElement";

const FileViewerContainer = styled.div`
  display: flex;
`;

class FileViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            supportedImageTypes: [
                'png',
                'jpeg',
                'jpeg'
            ]
        };
    }

    getViewerType = () => {
        return ImageElement;
    }

    openFileViewerModal = () => {
        const { file: { filePath }, imgUrl, openModalAction } = this.props;
        const path = filePath ? filePath : imgUrl
        openModalAction(MODAL_TYPE_FILE_VIEWER, path);
    }

    render() {
        const { file: { path }, file, imgUrl } = this.props;
        const link = imgUrl ? imgUrl : path
        const Viewer = this.getViewerType();
        return (
            <FileViewerContainer onClick={this.openFileViewerModal}>
                <Viewer src={link} {...file} />
            </FileViewerContainer>
        );
    }
}


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        file: state.file,
    }
};

const mapDispatchToProps = { openModalAction };

export default connect(mapStateToProps, mapDispatchToProps)(
    FileViewer
);