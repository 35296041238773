import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from '../../redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga'

import Header from '../Header'
import Routes from '../Routes'
import Theme from '../Theme'

function App () {
    ReactGA.initialize('UA-147993239-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

    const [topScroll, setTopScroll] = useState(true)

    const listenScrollEvent = () => {
        if (window.scrollY > 1) {
            setTopScroll(false)
        } else {
            setTopScroll(true)
        }
    }

    useEffect(() => {
        const eventListener = window.addEventListener('scroll', listenScrollEvent)

        return window.removeEventListener('scroll', eventListener)
    })

    const lite = !!process.env.REACT_APP_LITE

    return (
        <Provider store={store}>
            <Theme>
                <Router>
                    <Header lite={lite} topScroll={topScroll} />
                    <Routes lite={lite} />
                </Router>
            </Theme>
        </Provider>
    )
}

export default App
