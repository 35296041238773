import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal'

import { connect } from 'react-redux'
import { hideAlert, showAlert } from './actions'

const AlertContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  justify-content: center;
  padding: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.invertedText};
  box-shadow: 0 2px 2px ${({ theme }) => theme.colors.backdrop};
  font-weight: bold;
`

const Alert = ({ message, visible, hideAlert, duration = 5000 }) => {
    if (visible) {
        setTimeout(() => hideAlert(), duration)
    }
    return (
        <Reveal when={visible}>
            <AlertContainer onClick={hideAlert}>
                {message}
            </AlertContainer>
        </Reveal>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        ...state.alert
    }
}

const mapDispatchToProps = { hideAlert, showAlert }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alert)
