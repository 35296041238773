import React, { useCallback, useEffect } from 'react'
import PageContainer from '../PageContainer'
import { PageTitle } from '../../components/Typography'
import { Field, Formik } from 'formik'
import Input from '../../components/Input'
import { FormButtonContainer } from '../../components/Form'
import { Button } from '../../components/Buttons'
import * as Yup from 'yup'
import styled from 'styled-components'
import { getSlideshow } from '../SlideshowPage/actions'
import { startLoading, stopLoading } from '../PageContainer/actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {addSlideshow, editSlideshow, getSingleSlideshowRequestById} from './actions'
import SlideForm from '../SlideForm'
import SlideSelector from '../../components/SlideSelector'
import SlideshowActionMenu from "../../components/ActionMenu/SlideshowActionMenu";

const SlideshowForm = (props) => {
    const { edit, addSlideshow, editSlideshow, slideshow, startLoading, stopLoading, match, history, getSingleSlideshowRequestById } = props
    const slideshowId = match.params.slideshowId

    const getSlideshowById = useCallback(async () => {
        const { history } = props

        try {
            startLoading()
            await getSingleSlideshowRequestById(slideshowId)
            stopLoading()
        } catch (error) {
            console.error('Error retrieving slideshow', error)
            stopLoading()
            history.push('/404')
        }
    }, [getSingleSlideshowRequestById, slideshowId, props, startLoading, stopLoading])

    const editSlideshowById = useCallback(async (slideshowId, data) => {
        try {
            startLoading()
            await editSlideshow(slideshowId, data)
            history.push(`/slideshows/${slideshowId}/view`)
            stopLoading()
        } catch (error) {
            console.error('Error editing slideshow', error)
            stopLoading()
        }
    }, [history, slideshowId, editSlideshow, startLoading, stopLoading])

    const createSlideshow = async (data) => {
        try {
            console.log('Creating a new slideshow')
            startLoading()
            const slideshowId = await addSlideshow(data)
            history.push(`/slideshows/${slideshowId}/view`)
        } catch (error) {
            console.error('Error creating a new slideshow', error)
            stopLoading()
        }
    }

    useEffect(() => {
        let isMounted = true
        // Update the document title using the browser API
        if (edit) {
            getSlideshowById()
        }
        return () => {
            return isMounted = false
        }
    }, [])

    const newSlideshow = {
        name: '',
        slides: []
    }

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        slideIds: Yup.array().of(Yup.number()).required('Please select at least one slide')
    })

    const SlideshowFormContainer = styled.form`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 20px;
    `

    return (
        <PageContainer>
            <PageTitle>
                { edit ? 'Edit' : 'Create' } Slideshow
            </PageTitle>
            <Formik
                enableReinitialize={true}
                initialValues={edit ? slideshow : newSlideshow}
                validationSchema={schema}
                onSubmit={(values) => {

                    if (edit) {
                        editSlideshowById(slideshow.id, values)
                    } else {
                        createSlideshow(values)
                    }
                }}
                validateOnBlur={true}
            >{formikProps => (
                    <SlideshowFormContainer onSubmit={formikProps.handleSubmit}>
                        <Field
                            component={Input}
                            name="name"
                        />
                        <SlideSelector onSelect={(slideIds) => formikProps.setFieldValue('slideIds', slideIds) } />
                        <FormButtonContainer>
                            <Button type="submit" disabled={!formikProps.isValid}>
                                Save
                            </Button>
                        </FormButtonContainer>
                    </SlideshowFormContainer>
                )}
            </Formik>
            <SlideForm />
            <SlideshowActionMenu />
        </PageContainer>
    )
}

const mapStateToProps = (state /*, ownProps */) => {
    return {
        slideshow: state.slideshow
    }
}

const mapDispatchToProps = { getSlideshow, addSlideshow, editSlideshow, getSingleSlideshowRequestById, startLoading, stopLoading }

SlideshowForm.propTypes = {
    edit: PropTypes.bool
}

SlideshowForm.defaultProps = {
    slideshow: {
        name: '',
        slides: []
    },
    edit: false
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(SlideshowForm)
)
