import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Button } from '../Buttons'
import { deleteFileAction } from '../../containers/FilePage/actions'
import { MODAL_TYPE_DELETE_FILE, MODAL_TYPE_DELETE_POST, MODAL_TYPE_DELETE_SLIDESHOW } from './constants'
import { ModalButtons, ModalContainer, ModalTitle } from '../../styles/Modal'
import { deleteSlideshowAction } from '../../containers/Slideshow/actions'

const DeleteModal = ({ type, title = 'Delete the Thing', cancelText = 'Close/Cancel', submitText = 'Delete', closeModal, history, ...props }) => {
    const onDeleteClick = () => {
        switch (type) {
        case (MODAL_TYPE_DELETE_POST):
            deletePost()
            break
        case (MODAL_TYPE_DELETE_FILE):
            deleteFile()
            break
        case (MODAL_TYPE_DELETE_SLIDESHOW):
            deleteSlideshow()
            break
        default:
            console.error('---- Please provide a delete modal type ----')
            break
        }
    }

    const deletePost = async () => {
        const { deletePost, post } = props
        try {
            await deletePost(post.id)
            history.push('/posts')
            closeModal()
        } catch (error) {
            console.error('Error deleting post', error)
        }
    }

    const deleteFile = async () => {
        const { deleteFileAction, file } = props
        try {
            await deleteFileAction(file.id)
            history.push('/files')
            closeModal()
        } catch (error) {
            console.error('Error deleting file', error)
        }
    }

    const deleteSlideshow = async () => {
        const { deleteSlideshowAction, slideshow } = props
        try {
            await deleteSlideshowAction(slideshow.id)
            history.push('/slideshows')
            closeModal()
        } catch (error) {
            console.error('Error deleting file', error)
        }
    }

    return (
        <ModalContainer
            p={[2, 3, 4]}
            width={[0.9, 0.8, 0.7, 1 / 2]}
        >
            <ModalTitle>
                {title}
            </ModalTitle>
            <ModalButtons>
                <Button onClick={closeModal}>
                    {cancelText}
                </Button>
                <Button onClick={onDeleteClick}>
                    {submitText}
                </Button>
            </ModalButtons>
        </ModalContainer>
    )
}

DeleteModal.propTypes = {
    title: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    type: PropTypes.string.isRequired
}

const mapDispatchToProps = { deleteFileAction, deleteSlideshowAction }

const mapStateToProps = (state /*, ownProps */) => {
    return {
        file: state.file,
        slideshow: state.slideshow
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(DeleteModal)
)
