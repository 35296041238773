import React from 'react'
import styled from 'styled-components'

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextareaElement = styled.textarea`
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    line-height: ${({ theme }) => theme.space[4]}px;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.foreground};
    padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[3]}px`};
    resize: none;
    font-family: inherit;
`

const TextareaLabel = styled.span`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  line-height: ${({ theme }) => theme.space[4]}px;
`

const Textarea = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    return (
        <TextareaContainer>
            <TextareaLabel>
                {field.name}
            </TextareaLabel>
            <TextareaElement {...field} {...props} />
        </TextareaContainer>
    )
}

Textarea.defaultProps = {
    rows: 10,
    cols: 5
}

export default Textarea
