import * as types from "./constants";

export const slideshowReducer = (state = {}, {type, payload}) => {
    switch (type) {
        case types.GET_SLIDESHOW_SUCCESS:
            const newState = {...state};
            newState[payload.name] = payload;
            return newState;
        case types.GET_SLIDESHOW_FAILURE:
            return state;
        default:
            return state;
    }
};